import { Box, SxProps, Theme, Typography } from '@mui/material'
import { getPillarsColors } from '../../../../../Utils'
import { Legend } from '../../../components/legend'
import ScoreGauge from '../../../components/score-gauge'
import { ScoreRangeHint } from '../../../components/score-range-hint/ScoreRangeHint'
import SolidGauge from '../../../components/solid-gauge-chart'
import { useClient } from '../../../hooks/useClient'
import { useOverallSentimentScore } from '../../../hooks/useOverallSentimentScore'
import { useSentimentsByPillar } from '../../../hooks/useSentimentsByPillar'

const useStyles = () => {
  return {
    title: {
      fontSize: '2.5rem',
      fontWeight: 300,
    },
    subtitle: {
      fontSize: '1.5rem',
      fontWeight: 300,
    },
    content: {
      marginTop: '1rem',
      backgroundColor: '#0a0b33',
      borderRadius: '1rem',
    },
    overall: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '4rem',
    },
    chartTitle: {
      textAlign: 'center',
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    charts: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
    },
    chartsFooter: {
      display: 'flex',
      flexDirection: 'row',
      gap: '3rem',
    },
    table: {
      maxWidth: '400px',
      backgroundColor: 'rgb(10, 11, 51)',
    },
    tableCell: {
      color: 'white',
      borderBottom: `1px solid #929292`,
    },
  } satisfies Record<string, SxProps<Theme>>
}

export const PillarSentimentScoresPage = () => {
  const styles = useStyles()
  const [client] = useClient()
  const [
    {
      environment,
      employees,
      governance,
      dei,
      community,
      customers,
      financials,
    },
  ] = useSentimentsByPillar()

  const { sentimentScore } = useOverallSentimentScore()

  const colors = getPillarsColors()

  return (
    <Box m={3}>
      <Typography color="primary" sx={styles.title}>
        Pillar sentiment scores
      </Typography>
      <Box sx={styles.content} p={3}>
        <Typography color="primary" sx={styles.subtitle}>
          Sentiment by Pillar
        </Typography>

        <Box sx={styles.overall} m={3}>
          <Box>
            <ScoreGauge
              score={sentimentScore.score}
              averageScore={sentimentScore.averageScore}
              leaderScore={sentimentScore.leaderScore}
              company={client?.name ?? ''}
            />
            <Typography color="primary" sx={styles.chartTitle}>
              Overall Sentiment
            </Typography>
          </Box>

          <Box display={'flex'} flexDirection={'column'}>
            <Box mb={1}>
              <Legend
                score={sentimentScore.score}
                averageScore={sentimentScore.averageScore}
                leaderScore={sentimentScore.leaderScore}
              />
            </Box>
            <ScoreRangeHint />
          </Box>
        </Box>

        <Box sx={styles.charts} m={3} mt={5}>
          <SolidGauge
            title="Environment"
            data={environment?.score}
            industryAvg={environment?.averageScore}
            industryLeader={environment?.leaderScore}
            color={colors.get('Environment')}
          />
          <SolidGauge
            title="Employees"
            data={employees?.score}
            industryAvg={employees?.averageScore}
            industryLeader={employees?.leaderScore}
            color={colors.get('Employees')}
          />
          <SolidGauge
            title="Governance"
            data={governance?.score}
            industryAvg={governance?.averageScore}
            industryLeader={governance?.leaderScore}
            color={colors.get('Governance')}
          />
          <SolidGauge
            title="DEI"
            data={dei?.score}
            industryAvg={dei?.averageScore}
            industryLeader={dei?.leaderScore}
            color={colors.get('DEI')}
          />
          <SolidGauge
            title="Community"
            data={community?.score}
            industryAvg={community?.averageScore}
            industryLeader={community?.leaderScore}
            color={colors.get('Community')}
          />
          <SolidGauge
            title="Customers"
            data={customers?.score}
            industryAvg={customers?.averageScore}
            industryLeader={customers?.leaderScore}
            color={colors.get('Customers')}
          />
          <SolidGauge
            title="Financials"
            data={financials?.score}
            industryAvg={financials?.averageScore}
            industryLeader={financials?.leaderScore}
            color={colors.get('Financials')}
          />
        </Box>
      </Box>
    </Box>
  )
}
