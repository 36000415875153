import { graphql } from 'gql.tada'

export const LOGIN = graphql(`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      email
      roles {
        name
        rank
      }
      respondentUuid
      clientName
      clientCode
      clientId
    }
  }
`)

export const LOGOUT = graphql(`
  mutation Logout {
    logout
  }
`)

export const CREATE_USER = graphql(`
  mutation CreateUser($input: UserInput!) {
    createUser(input: $input) {
      code
      success
      message
      user {
        id
        email
        roleId
        role {
          name
          rank
        }
      }
    }
  }
`)

export const DELETE_USER = graphql(`
  mutation DeleteUser($email: String!) {
    deleteUser(email: $email) {
      code
      success
      message
      user {
        email
      }
    }
  }
`)

export const EDIT_USER = graphql(`
  mutation EditUser($input: UserInput!) {
    editUser(input: $input) {
      code
      success
      message
      user {
        id
        email
        roleId
        role {
          name
          rank
        }
        accountStatus
      }
    }
  }
`)

export const UPLOAD_USERS_ROSTER = graphql(`
  mutation UploadUsersRosterFile($file: Upload!, $clientId: ID!) {
    uploadUsersRosterFile(file: $file, clientId: $clientId) {
      code
      success
      message
      result
    }
  }
`)

export const SEND_RESET_PASSWORD_EMAIL = graphql(`
  mutation SendResetPasswordEmail($email: String!, $redirectTo: String!) {
    sendResetPasswordEmail(email: $email, redirectTo: $redirectTo) {
      code
      success
      message
    }
  }
`)

export const VERIFY_RESET_PASSWORD_TOKEN = graphql(`
  mutation VerifyResetPasswordToken($userId: ID!, $token: String!) {
    verifyResetPasswordToken(userId: $userId, token: $token) {
      code
      success
      message
      email
    }
  }
`)

export const CHANGE_USER_PASSWORD = graphql(`
  mutation ChangeUserPassword(
    $token: String!
    $userId: ID!
    $newPassword: String!
  ) {
    changeUserPassword(
      token: $token
      userId: $userId
      newPassword: $newPassword
    ) {
      code
      success
      message
    }
  }
`)

export const CHANGE_PASSWORD = graphql(`
  mutation ChangePassword($newPassword: String!) {
    changePassword(newPassword: $newPassword) {
      code
      success
      message
    }
  }
`)

export const ADD_EMAIL_TO_WHITELIST = graphql(`
  mutation AddEmailToWhitelist($clientId: ID!, $emailOrDomain: String!) {
    addEmailToWhitelist(clientId: $clientId, emailOrDomain: $emailOrDomain) {
      code
      success
      message
    }
  }
`)

export const CHANGE_VARIABLE_SCORE = graphql(`
  mutation changeVariableScore(
    $clientId: ID
    $groupId: ID!
    $variableName: String!
    $value: Float
  ) {
    changeVariableScore(
      clientId: $clientId
      groupId: $groupId
      variableName: $variableName
      value: $value
    ) {
      code
      success
      message
      data {
        score
        name
        current
      }
    }
  }
`)

export const RESET_VARIABLE_SCORES = graphql(`
  mutation resetVariableScore($clientId: ID, $groupId: ID!) {
    resetVariableScore(clientId: $clientId, groupId: $groupId) {
      code
      success
      message
      data {
        score
        name
        current
      }
    }
  }
`)

export const PULL_REFINITIV_DATA = graphql(`
  mutation pullRefinitivData($start: String, $end: String) {
    pullRefinitivData(start: $start, end: $end) {
      status
      message
      jobId
    }
  }
`)

export const CREATE_CHAT = graphql(`
  mutation createChat {
    createChat {
      id
    }
  }
`)

export const CREATE_MESSAGE = graphql(`
  mutation createMessage(
    $chatId: ID!
    $input: String!
    $clientId: ID
    $startDate: String
    $endDate: String
  ) {
    createMessage(
      chatId: $chatId
      input: $input
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      content
      contentType
    }
  }
`)
