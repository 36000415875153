import { Maybe } from 'graphql/jsutils/Maybe'
import { ReactNode, useState } from 'react'
import { UserInfo, UserInfoContext } from '../Context'

export const UserInfoProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [userInfo, setUserInfo] = useState<Maybe<UserInfo>>(null)

  return (
    <UserInfoContext.Provider value={{ userInfo, setUserInfo }}>
      {children}
    </UserInfoContext.Provider>
  )
}
