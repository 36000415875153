import { useQuery } from '@apollo/client'
import { cloneDeep } from '@apollo/client/utilities'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { PerceptionRealityGap } from '../../../__generated__/graphql'
import { graphql } from '../../../graphql'

const GET_PERCEPTION_REALITY_GAP = graphql(`
  query GetPerceptionRealityGap($clientId: ID, $industryId: ID) {
    perceptionRealityGap(clientId: $clientId, industryId: $industryId) {
      perception {
        score
        averageScore
        variableId
        variableName
        dataType
      }
      signature {
        name
        pillar
        value
        optimum
      }
    }
  }
`)

export function usePerceptionRealityGapData() {
  const params = useParams()
  const [searchParams] = useSearchParams()

  const [data, setData] = useState<Maybe<PerceptionRealityGap>>()

  const query = useQuery(GET_PERCEPTION_REALITY_GAP, {
    variables: {
      clientId: params.clientId,
      industryId: params.industryId,
    },
    onCompleted: data => {
      setData(
        cloneDeep(data.perceptionRealityGap) as Maybe<PerceptionRealityGap>,
      )
    },
  })

  const perception =
    data?.perception?.filter(p => p?.dataType === searchParams.get('source')) ??
    []
  const reality = data?.signature ?? []
  const perceptionRealityGap = reality
    .map(signature => {
      const p = perception.find(p => p?.variableName === signature?.name)
      return {
        ...signature,
        gap: (p?.score ?? 0) - (signature?.value ?? 0),
      }
    })
    .sort((a, b) => b.gap - a.gap)

  const opportunities = perceptionRealityGap.slice(-3)

  const risks = perceptionRealityGap.slice(0, 3)

  return [
    {
      opportunities,
      risks,
      perception,
      reality,
    },
    query,
  ] as const
}
