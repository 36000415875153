import { FeatureFlag, FeatureFlagContext } from '../Context/feature-flags'

type Props = {
  children: React.ReactNode
  features: FeatureFlag[]
}

export const FeatureFlagProvider = ({ children, features }: Props) => {
  return (
    <FeatureFlagContext.Provider value={features}>
      {children}
    </FeatureFlagContext.Provider>
  )
}
