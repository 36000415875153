import { Maybe } from 'graphql/jsutils/Maybe'
import Highcharts from 'highcharts'
import { HighchartsReact } from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more'
import NoDataModule from 'highcharts/modules/no-data-to-display'
import SolidGaugeModule from 'highcharts/modules/solid-gauge'

HighchartsMore(Highcharts)
SolidGaugeModule(Highcharts)
NoDataModule(Highcharts)

type SolidGaugeProps = {
  data?: Maybe<number>
  title: string
  color?: string
  company?: string
  industryAvg?: Maybe<number>
  industryLeader?: Maybe<number>
}

const chartOptions = (props: SolidGaugeProps): Highcharts.Options => {
  return {
    chart: {
      height: 180,
      width: 180,
      type: 'solidgauge',
      backgroundColor: 'transparent',
      style: {
        fontFamily: 'Lato',
      },
    },
    lang: {
      loading: '',
      noData: 'No mentions',
    },

    title: {
      text: props.title,
      verticalAlign: 'bottom',
      style: {
        color: '#fff',
        fontSize: '1.25rem',
      },
    },

    pane: {
      center: ['50%', '50%'],
      size: '100%',
      innerSize: '50%',
      startAngle: 0,
      endAngle: 360,
      background: [
        {
          backgroundColor: 'transparent',
          borderColor: 'rgb(58, 63, 91)',
          innerRadius: '80%',
          outerRadius: '100%',
          shape: 'arc',
        },
      ],
    },

    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: undefined,
      tickAmount: 2,
      visible: false,
      maxPadding: 0,
      minPadding: 0,
      endOnTick: false,
    },

    plotOptions: {
      solidgauge: {
        clip: false,
        dataLabels: {
          format: `<div class="solidgauge-label" style="text-align:center; font-size:1.5rem">{y}</div>`,
          position: 'center',
          verticalAlign: 'middle',
          borderWidth: 0,
          useHTML: true,
          style: {
            fontFamily: 'Lato',
            color: '#fff',
          },
        },
      },
    },

    series: [
      {
        type: 'solidgauge',
        innerRadius: 80 + '%',
        data: props.data
          ? [
              {
                y: props.data,
                color: props.color,
              },
            ]
          : [],
      },
    ],

    legend: {
      enabled: false,
    },

    tooltip: {
      enabled: false,
    },

    exporting: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },
  }
}

export const SolidGauge = (props: SolidGaugeProps) => {
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={chartOptions(props)} />
    </div>
  )
}

export default SolidGauge
