import { useMediaQuery } from '@mui/material'
import {
  Dispatch,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react'
import { DISPLAY_MODE_LOCAL_STORAGE_KEY } from '../Common/constants'
import { DisplayMode } from '../types'

type ContextType = {
  mode: DisplayMode
  setMode: Dispatch<SetStateAction<DisplayMode>>
}
export const DisplayModeContext = createContext<ContextType>({
  mode: 'light',
  setMode: () => void null,
})

export const DisplayModeProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const [mode, setMode] = useState<DisplayMode>(
    // FIXME: Uncomment this line when dark mode is ready to be used
    // prefersDarkMode ? 'dark' : 'light',
    'light',
  )

  useEffect(() => {
    const storedMode = localStorage.getItem(
      DISPLAY_MODE_LOCAL_STORAGE_KEY,
    ) as DisplayMode | null

    if (storedMode) {
      setMode(storedMode)
    }
  }, [])

  return (
    <DisplayModeContext.Provider value={{ mode, setMode }}>
      {children}
    </DisplayModeContext.Provider>
  )
}
