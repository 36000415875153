import { Box, Typography, useTheme } from '@mui/material'
import mahaLogo from 'Assets/maha-new-logo.webp'

type FooterProps = {
  pageNumber: number
}

const useStyles = () => {
  const theme = useTheme()
  return {
    container: {
      borderTop: `1px dashed ${theme.palette.primary.main}`,
      paddingTop: '1rem',
      display: 'flex',
      flexDirection: 'row',
      gap: '1rem',
      width: 'fit-content',
    },
    link: {
      fontSize: '.75rem',
      borderRight: `1px solid ${theme.palette.grey[100]}`,
      paddingRight: '1rem',
      '& a': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
      },
    },
    number: {
      fontSize: '.75rem',
      borderRight: `1px solid ${theme.palette.grey[100]}`,
      paddingRight: '1rem',
    },
    copyright: {
      fontSize: '.75rem',
    },
  }
}

export const Footer = (props: FooterProps) => {
  const styles = useStyles()
  return (
    <>
      <Box sx={styles.container} m={3}>
        <Typography sx={styles.number}>{props.pageNumber}</Typography>
        <Typography sx={styles.link}>
          <a href="https://www.maha.global/">www.maha.global</a>
        </Typography>
        <Typography sx={styles.copyright}>
          Copyright
          <span>&nbsp;© {new Date().getFullYear()} MAHA</span>
        </Typography>
      </Box>
      <Box m={3}>
        <img width={70} height={70} src={mahaLogo} />
      </Box>
    </>
  )
}
