import { Maybe } from 'graphql/jsutils/Maybe'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts/highstock'
import SolidGauge from 'highcharts/modules/solid-gauge'
import { ReputationScore } from '../../../../__generated__/graphql'

HighchartsMore(Highcharts)
SolidGauge(Highcharts)

type SolidChartProps = {
  data?: Maybe<ReputationScore>
  className?: string
}

const chartOptions = ({
  data,
}: Omit<SolidChartProps, 'className'>): Highcharts.Options => {
  return {
    chart: {
      type: 'solidgauge',
      backgroundColor: 'transparent',
      width: 300,
      height: 300,
    },

    title: {
      text: 'Reputation score',
      align: 'center',
      floating: true,
      margin: 0,
      y: 175,
      style: {
        color: '#636363',
        fontSize: '.75rem',
      },
    },
    pane: {
      center: ['50%', '50%'],
      size: '100%',
      innerSize: '50%',
      startAngle: -130,
      endAngle: 130,
      background: [
        {
          backgroundColor: '#dedede',
          borderColor: '#F1F2F4',
          innerRadius: '50%',
          outerRadius: '100%',
          shape: 'arc',
        },
      ],
    },

    exporting: {
      enabled: false,
    },

    tooltip: {
      enabled: false,
    },

    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickWidth: 0,
      minorTickInterval: undefined,
      tickAmount: 2,
      visible: false,
      maxPadding: 20,
      minPadding: 0,
      endOnTick: false,
    },

    plotOptions: {
      solidgauge: {
        clip: false,
        dataLabels: {
          format: `<div class="solidgauge-label" style="color: #01d7ca; fontSize: 45px; fontFamily: Lato;">{y}</div>`,
          position: 'center',
          verticalAlign: 'middle',
          borderWidth: 0,
          useHTML: true,
        },
      },
    },

    series: [
      {
        type: 'solidgauge',
        innerRadius: '50%',
        data: [
          {
            y: data?.score ?? 0,
            color: '#01d7ca',
          },
        ],
      },
    ],

    legend: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },
  }
}

const SolidChart = (props: SolidChartProps) => {
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions(props)}
      {...props}
    />
  )
}

export default SolidChart
