import {
  Box,
  Divider,
  Drawer,
  List,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import { NavigationItem } from './components/NavigationItem'
import { DRAWER_WIDTH } from '../../../../../Common/constants'

type Props = {
  open: boolean
  handleClose: () => void
}

export const Navigation = (props: Props) => {
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Drawer
      elevation={16}
      variant={'permanent'}
      open={props.open}
      onClose={props.handleClose}
      sx={{
        width: downMd ? '150px' : DRAWER_WIDTH,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: downMd ? '150px' : DRAWER_WIDTH,
          boxSizing: 'border-box',
          background: theme.color.backgroundGradient,
          color: theme.color.white,
          borderRight: 'none',
        },
      }}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Toolbar sx={{ height: '130px' }} />
      <Box
        sx={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          marginBottom: '80px',
        }}
      >
        <List
          sx={{
            width: '100%',
            maxWidth: DRAWER_WIDTH,
            bgcolor: 'inherit',
            color: 'inherit',
          }}
          component="nav"
        >
          <NavigationItem
            to="/v2/dashboard/purpose-signature/home"
            label="Home"
            height="90px"
          />
          <Divider
            sx={{
              border: '1px solid transparent',
              borderImageSource: `linear-gradient(90deg, ${theme.color.divider1} 0%, ${theme.color.divider2} 100%)`,
              borderImageSlice: 1,
            }}
          />
          <NavigationItem
            to="/v2/dashboard/purpose-signature/community"
            label="Community"
          />
          <NavigationItem to="/dashboard" label="CUSTOMERS" />
          <NavigationItem to="/dashboard" label="DEI" />
          <NavigationItem
            to="v2/dashboard/purpose-signature/home"
            label="Employees"
          />
          <NavigationItem to="/dashboard" label="Environment" />
          <NavigationItem to="/dashboard" label="Financials" />
          <NavigationItem to="/dashboard" label="Governance" />
          <Divider
            sx={{
              border: '1px solid transparent',
              borderImageSource: `linear-gradient(90deg, ${theme.color.divider1} 0%, ${theme.color.divider2} 100%)`,
              borderImageSlice: 1,
            }}
          />
          <NavigationItem
            to="/v2/dashboard/purpose-signature/reputation"
            label="Reputation"
          />
          <NavigationItem
            to="/v2/dashboard/purpose-signature/sentiment"
            label="Sentiment"
          />
          <NavigationItem
            to="/v2/dashboard/purpose-signature/behavior"
            label="Behavior"
          />
          <NavigationItem
            to="/v2/dashboard/purpose-signature/relevance"
            label="Relevance"
          />
        </List>
      </Box>
    </Drawer>
  )
}
