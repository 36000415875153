import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { graphql } from '../../../graphql'

const GET_SIGNATURE_EXECUTIVE_SUMMARY = graphql(`
  query GetSignatureExecutiveSummary($industryId: ID, $clientId: ID!) {
    signatureExecutiveSummary(industryId: $industryId, clientId: $clientId) {
      drivers {
        variableId
        variableName
        weight
      }
      competitorsGains {
        companyId
        companyName
        score
        differenceVsPrevious
      }
    }
  }
`)

export function useSignatureExecutiveSummary() {
  const params = useParams()

  const query = useQuery(GET_SIGNATURE_EXECUTIVE_SUMMARY, {
    variables: {
      clientId: params.clientId as string,
      industryId: params.industryId,
    },
    skip: !params.clientId,
  })

  return [query.data?.signatureExecutiveSummary, query] as const
}
