import { useContext } from 'react'
import { TimeSeriesContext } from '../../../../../../../Context'
import { DateRangeCalendar } from '../date-range-calendar'
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'

export const TimeSeriesFilters = () => {
  const { startDate, endDate, setStartDate, setEndDate } =
    useContext(TimeSeriesContext)
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      {!downMd && (
        <Typography variant="h6" sx={{ marginRight: '10px' }}>
          Select Date:{' '}
        </Typography>
      )}

      <DateRangeCalendar
        startDate={startDate}
        endDate={endDate}
        onDateRangeChange={(startDate, endDate) => {
          setStartDate?.(startDate)
          setEndDate?.(endDate)
        }}
      ></DateRangeCalendar>
    </Box>
  )
}
