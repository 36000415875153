import { useContext, useEffect, useRef } from 'react'
import { CurrentPageContext } from '../providers/CurrentPageProvider'

export function useCurrentPage() {
  const [, setPage] = useContext(CurrentPageContext)
  const page = useRef(1)
  useEffect(() => {
    setPage?.(current => {
      page.current = current
      return current + 1
    })
  }, [setPage])

  return page.current
}
