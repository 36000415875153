import { useQuery } from '@apollo/client'
import { cloneDeep } from '@apollo/client/utilities'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ReputationScore } from '../../../__generated__/graphql'
import { graphql } from '../../../graphql'

const GET_REPUTATION_SCORE = graphql(`
  query GetReputationScore($clientId: ID!, $industryId: ID) {
    reputationScore(clientId: $clientId, industryId: $industryId) {
      score
      difference
      compareVsPeriod
    }
  }
`)

export function useReputationScore() {
  const params = useParams()
  const [data, setData] = useState<Maybe<ReputationScore>>()

  useQuery(GET_REPUTATION_SCORE, {
    variables: {
      clientId: params.clientId ?? '',
      industryId: params.industryId,
    },
    onCompleted(data) {
      setData(cloneDeep(data.reputationScore))
    },
  })

  return [data] as const
}
