import { Box, SxProps, Theme, Typography } from '@mui/material'
import { Legend } from '../../components/legend'
import ScoreGauge from '../../components/score-gauge'
import { useClient } from '../../hooks/useClient'
import { useOverviewScore } from '../../hooks/useOverviewScore'

const useStyles = () => {
  return {
    title: {
      fontSize: '3.5rem',
      fontWeight: 600,
    },
    subtitle: {
      fontSize: '2rem',
      fontWeight: 300,
    },
    chartTitle: {
      margin: '1rem 0',
      fontSize: '1.25rem',
      fontWeight: 600,
    },
    container: {
      marginTop: '5rem',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  } satisfies Record<string, SxProps<Theme>>
}

export const OverviewPage = () => {
  const styles = useStyles()
  const [client] = useClient()
  const { sentiment, reality, gap } = useOverviewScore()
  return (
    <Box m={3}>
      <Typography sx={styles.title}>Overview</Typography>
      <Typography color="secondary" sx={styles.subtitle}>
        Here are your summary scores for each of your core areas:
      </Typography>

      <Box sx={styles.container}>
        <Box sx={styles.box}>
          <ScoreGauge
            score={sentiment.score}
            averageScore={sentiment.averageScore}
            leaderScore={sentiment.leaderScore}
            company={client?.name ?? ''}
          />
          <Typography sx={styles.chartTitle}>
            Sentiment{' '}
            <Typography component="span" color="secondary">
              (Perception)
            </Typography>
          </Typography>
          <Legend {...sentiment} />
        </Box>
        <Box sx={styles.box}>
          <ScoreGauge
            score={reality.score}
            averageScore={reality.averageScore}
            leaderScore={reality.leaderScore}
            company={client?.name ?? ''}
          />
          <Typography sx={styles.chartTitle}>
            Behavior{' '}
            <Typography component="span" color="secondary">
              (Reality)
            </Typography>
          </Typography>
          <Legend {...reality} />
        </Box>
        <Box sx={styles.box}>
          <ScoreGauge
            score={gap.score}
            averageScore={gap.averageScore}
            leaderScore={gap.leaderScore}
            company={client?.name ?? ''}
          />
          <Typography sx={styles.chartTitle}>Sentiment-Behavior Gap</Typography>
          <Legend {...gap} />
        </Box>
      </Box>
    </Box>
  )
}
