import React, { ReactNode, createContext, useState } from 'react'

type Navigation = {
  animationTimeOut?: number
  moveForward?: (cb: () => void) => void
  moveBackward?: (cb: () => void) => void
  backward?: boolean
}

export const NavigationContext = createContext<Navigation>({})

const NavigationProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const animationTimeOut = 250,
    [backward, setBackward] = useState(false),
    moveBackward = (callback: () => void) => {
      setBackward(true)
      setTimeout(() => {
        callback()
      }, animationTimeOut)
    },
    moveForward = (callback: () => void) => {
      setBackward(false)
      setTimeout(() => {
        callback()
      }, animationTimeOut)
    }

  return (
    <NavigationContext.Provider
      value={{
        animationTimeOut,
        moveForward,
        moveBackward,
        backward,
      }}
    >
      {children}
    </NavigationContext.Provider>
  )
}

export default NavigationProvider
