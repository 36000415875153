import { Maybe } from 'graphql/jsutils/Maybe'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import { PurposePillar } from '../../../../__generated__/graphql'

type ColumnLineDrilldownProps = {
  data: Maybe<Maybe<PurposePillar>[]>
  optimum?: number[]
  name?: string
  optimumName?: string
  className?: string
}

const chartOptions = ({
  data,
  name,
}: Pick<ColumnLineDrilldownProps, 'data' | 'name'>): Highcharts.Options => {
  return {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      height: 450,
      width: 900,
      animation: false,
    },

    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    xAxis: {
      type: 'category',
      labels: {
        style: {
          fontSize: '13px',
          fontFamily: 'Lato',
          fontWeight: 'normal',
          textDecoration: 'none',
          color: 'black',
        },
      },
    },
    yAxis: [
      {
        title: {
          enabled: false,
        } as Highcharts.AxisTitleOptions,
        max: 100,
      },
      {
        visible: false,
      },
    ],

    legend: {
      enabled: true,
    },

    tooltip: {
      enabled: false,
    },

    series: [
      {
        name: name + ' Score',
        type: 'column',
        color: '#0A2765',
        data: data?.map(el => ({
          name: el?.name ?? '',
          y: el?.value ? +el.value.toFixed(3) : undefined,
          color: el?.color ?? undefined,
        })),
      },

      {
        name: 'Optimal Score',
        type: 'spline',
        data: data?.map(el => ({
          name: el?.name ?? '',
          y: el?.optimum ? +el.optimum.toFixed(3) : undefined,
        })),
      },
    ],
  }
}

const SignatureMatchChart = (props: ColumnLineDrilldownProps) => {
  const options = chartOptions(props)
  return (
    <div className={props.className}>
      <HighchartsReact highcharts={Highcharts} options={options} {...props} />
    </div>
  )
}

export default SignatureMatchChart
