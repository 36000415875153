import { Maybe } from 'graphql/jsutils/Maybe'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Perception, Signature } from '../../../../__generated__/graphql'

type Props = {
  perception: Maybe<Perception>[]
  reality: Maybe<Signature>[]
}

const chartOptions = (props: Props): Highcharts.Options => {
  const categories = props.reality.map(p => p?.name)
  const reality = props.reality.map(p => p?.value)
  const perception = props.reality.map(signature => {
    const perception = props.perception.find(
      p => p?.variableName === signature?.name,
    )
    return perception?.score ?? 0
  })
  return {
    chart: {
      height: 500,
      width: 900,
      backgroundColor: 'transparent',
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: categories.filter((c): c is string => Boolean(c)),
    },
    yAxis: [
      {
        min: 0,
        max: 100,
        title: {
          text: 'Behavior',
        },
      },
      {
        min: 0,
        max: 100,
        title: {
          text: 'Sentiment',
        },
        opposite: true,
      },
    ],
    tooltip: {
      shared: true,
    },
    plotOptions: {
      series: {
        marker: { enabled: false },
        stacking: 'normal',
      },
    },
    series: [
      {
        name: 'Sentiment - Behavior Gap',
        type: 'column',
        showInLegend: false,
        enableMouseTracking: false,
        color: '#E5A1A4',
        data: perception.map((perception, i) => perception - (reality[i] ?? 0)),
      },

      {
        name: 'Behavior',
        type: 'column',
        color: '#0D235A',
        data: reality.map(d => d ?? null),
      },
      {
        name: 'Sentiment',
        type: 'spline',
        color: '#01D7CA',
        data: perception,
      },
    ],
  }
}

export const PerceptionRealityGapChart = (props: Props) => {
  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions({
        perception: props.perception,
        reality: props.reality,
      })}
    />
  )
}
