import {
  ListItemButton,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { NavLink, useLocation } from 'react-router-dom'

type Props = {
  label: string
  to: string
  height?: string
}

export const NavigationItem = ({ label, to, height = '50px' }: Props) => {
  const location = useLocation()
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <NavLink
      to={{
        pathname: to,
        search: location.search,
      }}
      style={{
        textDecoration: 'none',
        background: 'inherit',
        textTransform: 'uppercase',
        color: theme.color.white,
        display: 'block',
      }}
    >
      {({ isActive }) => (
        <ListItemButton
          sx={{
            height: { height },
            borderRight: `4px solid transparent`,
            '&.Mui-selected': {
              borderLeft: `4px solid ${theme.color.superRareJade};`,
            },
          }}
          selected={isActive}
        >
          <ListItemText>
            <Typography
              sx={{
                display: 'inline',
                paddingLeft: downMd ? '10px' : '80px',
              }}
              component="span"
              variant="h6"
              color={theme.color.white}
              fontFamily="Bebas Neue"
            >
              {label}
            </Typography>
          </ListItemText>
        </ListItemButton>
      )}
    </NavLink>
  )
}
