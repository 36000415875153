import { Box, SxProps, Theme, Typography, useTheme } from '@mui/material'
import SolidChart from '../../../components/reputation-score-chart'
import { ScoreRangeHint } from '../../../components/score-range-hint/ScoreRangeHint'
import SignatureMatchChart from '../../../components/signature-match-chart'
import { useClient } from '../../../hooks/useClient'
import { useReputationScore } from '../../../hooks/useReputationScore'
import { useSignatureMatch } from '../../../hooks/useSignatureMatch'

const useStyles = () => {
  const theme = useTheme()
  return {
    main: {
      display: 'flex',
      flexDirection: 'row',
    },
    sidebar: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    sidebarTitle: {
      fontSize: '2rem',
      fontWeight: 600,
    },
    sidebarText: {
      fontSize: '1.5rem',
      fontWeight: 300,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      flex: 3,
      backgroundColor: theme.palette.grey[200],
    },
    reputationScore: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
    },
    signatureMatch: {
      display: 'flex',
      justifyContent: 'center',
    },
  } satisfies Record<string, SxProps<Theme>>
}

export const ReputationScorePage = () => {
  const styles = useStyles()
  const [client] = useClient()
  const [reputationScoreData] = useReputationScore()
  const [signatureMatchData, signatureMatchQuery] = useSignatureMatch()

  return (
    <>
      <Box sx={styles.main}>
        <Box m={3} sx={styles.sidebar}>
          <Box mb={4}>
            <Typography color="secondary" sx={styles.sidebarTitle}>
              State of Clients reputation
            </Typography>
          </Box>
          <Box>
            <Typography color="primary" sx={styles.sidebarText}>
              The scoring index is a measure of the degree to which Client
              emulates the ideal reputation signature. This value will increase
              as the gaps between Client's reality scores (histogram bars) and
              industry optimum (dotted line) close.
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.content}>
          <Box m={3} sx={styles.reputationScore}>
            <Box>
              <SolidChart data={reputationScoreData} />
            </Box>
            <Box>
              <ScoreRangeHint theme="light" />
            </Box>
          </Box>

          <Box m={3} mt={0} sx={styles.signatureMatch}>
            {!signatureMatchQuery.loading &&
              !signatureMatchQuery.error &&
              !!signatureMatchData && (
                <SignatureMatchChart
                  name={client?.name ?? ''}
                  data={signatureMatchData}
                />
              )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
