import { DisplayMode } from './types'

const palette = [
  'contrast',
  'primary',
  'secondary',
  'background',
  'green',
  'green.light',
  'green.dark',
  'blue',
  'blue.light',
  'purple',
  'purple.light',
  'magenta',
  'teal',
  'text',
  'paper',
] as const

type T = (typeof palette)[number]

export const branded = {
  contrast: {
    light: '#FFFFFF',
    dark: '#000000',
  },
  primary: {
    light: '#3F51B5',
    dark: '#9FA8DA',
  },
  secondary: {
    light: '#01D7CA',
    dark: '#FAFAFA',
  },
  background: {
    light: '#F1F2F4',
    dark: '#212121',
  },
  green: {
    light: '#0F9E93',
    dark: '#277DA1',
  },
  'green.light': {
    light: '#15DBC6',
    dark: '#277DA1',
  },
  'green.dark': {
    light: '#227872',
    dark: '#277DA1',
  },
  blue: {
    light: '#0747B5',
    dark: '#90BE6D',
  },
  'blue.light': {
    light: '#7FA4C1',
    dark: '#F9844A',
  },
  purple: {
    light: '#191C5B',
    dark: '#6495ED',
  },
  'purple.light': {
    light: '#6495ED',
    dark: '#6495ED',
  },
  magenta: {
    light: '#4A608C',
    dark: '#4A608C',
  },
  teal: {
    light: '#1AD9B6',
    dark: '#1AD9B6',
  },
  text: {
    light: '#3D3D3D',
    dark: '#FFFFFF',
  },
  paper: {
    light: '#FFFFFF',
    dark: '#424242',
  },
} as const satisfies Record<T, Record<DisplayMode, string>>
