import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from 'react'

export const CurrentPageContext = createContext<
  [number, Dispatch<SetStateAction<number>>?]
>([1])

export const CurrentPageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [page, setPage] = useState(1)
  return (
    <CurrentPageContext.Provider value={[page, setPage] as const}>
      {children}
    </CurrentPageContext.Provider>
  )
}
