import { useQuery } from '@apollo/client'
import { cloneDeep } from '@apollo/client/utilities'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { SentimentScore } from '../../../__generated__/graphql'
import { graphql } from '../../../graphql'

const GET_SENTIMENT_SCORE = graphql(`
  query GetSentimentScore(
    $industryId: ID!
    $clientId: ID
    $startDate: String
    $endDate: String
  ) {
    sentimentScore(
      industryId: $industryId
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
    ) {
      score
      averageScore
      leaderScore
      dataType
    }
  }
`)

export function useOverallSentimentScore() {
  const params = useParams()
  const [searchParams] = useSearchParams()

  const [data, setData] = useState<Maybe<Maybe<SentimentScore>[]>>()

  useQuery(GET_SENTIMENT_SCORE, {
    variables: {
      clientId: params.clientId,
      industryId: params.industryId ?? '',
      startDate: searchParams.get('startDate'),
      endDate: searchParams.get('endDate'),
    },
    onCompleted: ({ sentimentScore }) => {
      setData(cloneDeep(sentimentScore) as Maybe<Maybe<SentimentScore>[]>)
    },
  })

  const sentimentScore = data?.find(
    d => d?.dataType === searchParams.get('source'),
  ) ?? {
    score: 0,
    averageScore: 0,
    leaderScore: 0,
  }

  return { sentimentScore } as const
}
