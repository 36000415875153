import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'
import { BackgroundLogo } from '../../components/background-logo/BackgroundLogo'
import { useClient } from '../../hooks/useClient'

const useStyles = () => {
  return {
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      justifySelf: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    },
    title: {
      fontSize: '3.5rem',
      fontWeight: 600,
    },
    subtitle: {
      fontSize: '2rem',
      fontWeight: 300,
    },
  } satisfies Record<string, React.CSSProperties>
}

export const FrontPage = () => {
  const styles = useStyles()
  const [client] = useClient()

  return (
    <>
      <Box style={styles.content}>
        <Typography color="primary" style={styles.title}>
          {client?.name}
        </Typography>
        <Typography color="primary" style={styles.title}>
          Reputation Report
        </Typography>
        <Typography color="secondary" style={styles.subtitle}>
          Prepared by MAHA Global
        </Typography>
        <Typography color="secondary" style={styles.subtitle}>
          {dayjs().format('DD/MM/YYYY')}
        </Typography>
      </Box>

      <BackgroundLogo />
    </>
  )
}
