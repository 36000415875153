import { useCurrentPage } from '../../hooks/useCurrentPage'
import { Footer } from '../footer/Footer'

const useStyles = () => {
  return {
    container: {
      height: '100vh',
      width: '100vw',
      backgroundColor: 'rgb(19, 21, 74)',
      display: 'grid',
      gridTemplateRows: '1fr auto',
      overflow: 'hidden',
      position: 'relative',
    },
    footer: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100vw',
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      alignItems: 'end',
    },
  } satisfies Record<string, React.CSSProperties>
}

type PageProps = {
  children: React.ReactNode
  skipFooter?: boolean
}

export const Page = (props: PageProps) => {
  const styles = useStyles()
  const page = useCurrentPage()
  return (
    <div style={styles.container}>
      {props.children}
      {!props.skipFooter && (
        <footer style={styles.footer}>
          <Footer pageNumber={page} />
        </footer>
      )}
    </div>
  )
}
