import { parseBoolean } from '..'

type Maybe<T> = T | null | undefined
type CustomProperties = {
  [key: string]: Maybe<string>
}

export function customEvent(event: string, args: CustomProperties) {
  if (import.meta.env.DEV) {
    if (event.length > 35) {
      console.error('GA event name should be less than 35 characters')
      return
    }

    if (!args.user_id) {
      console.error('GA event user_id is required')
      return
    }

    console.log('GA event', event, args)
  } else if (import.meta.env.PROD) {
    if (parseBoolean(import.meta.env.VITE_DISABLE_ANALYTICS)) {
      console.info('Analytics is disabled in this environment')
    } else {
      window.gtag('event', `dw_${event}`, args)
    }
  }
}

export function setUserProperties(args: CustomProperties) {
  if (import.meta.env.DEV) {
    console.log('GA user properties', args)
  } else if (import.meta.env.PROD) {
    if (parseBoolean(import.meta.env.VITE_DISABLE_ANALYTICS)) {
      console.info('Analytics is disabled in this environment')
    } else {
      window.gtag('set', 'user_properties', args)
    }
  }
}
