import { Box } from '@mui/material'
import HeaderLogo from './components/HeaderLogo'
import HeaderMenu from './components/HeaderMenu'

const Header = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '125px',
      }}
    >
      <HeaderLogo />
      <HeaderMenu />
    </Box>
  )
}

export default Header
