import { useQuery } from '@apollo/client'
import { cloneDeep } from '@apollo/client/utilities'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import {
  GET_HOME_PERCEPTION_REALITY_GAP_SCORE,
  GET_HOME_REPUTATION_SCORE,
  GET_HOME_SENTIMENT_SCORE,
} from '../../../Graphql/query'
import {
  PerceptionRealityGapScore,
  ReputationScore,
  SentimentScore,
} from '../../../__generated__/graphql'

export function useOverviewScore() {
  const params = useParams()
  const [searchParams] = useSearchParams()

  const [perception, setPerception] = useState<Maybe<Maybe<SentimentScore>[]>>()
  const [reputation, setReputation] = useState<Maybe<ReputationScore>>()
  const [perceptionRealityGap, setPerceptionRealityGap] =
    useState<Maybe<Maybe<PerceptionRealityGapScore>[]>>()

  useQuery(GET_HOME_SENTIMENT_SCORE, {
    variables: {
      clientId: params.clientId as string,
      industryId: params.industryId ?? '',
    },
    onCompleted: data => {
      setPerception(cloneDeep(data.sentimentScore) as SentimentScore[])
    },
  })

  useQuery(GET_HOME_REPUTATION_SCORE, {
    variables: {
      clientId: params.clientId as string,
      industryId: params.industryId ?? '',
    },
    onCompleted: data => {
      setReputation(cloneDeep(data.reputationScore))
    },
  })

  useQuery(GET_HOME_PERCEPTION_REALITY_GAP_SCORE, {
    variables: {
      clientId: params.clientId as string,
      industryId: params.industryId ?? '',
    },
    onCompleted: data => {
      setPerceptionRealityGap(
        cloneDeep(
          data.perceptionRealityGapScore,
        ) as PerceptionRealityGapScore[],
      )
    },
  })

  const defaultScore = {
    score: 0,
    averageScore: 0,
    leaderScore: 0,
  }
  const sentiment =
    perception?.find(d => d?.dataType === searchParams.get('source')) ??
    defaultScore
  const reality = reputation ?? defaultScore
  const gap =
    perceptionRealityGap?.find(
      d => d?.dataType === searchParams.get('source'),
    ) ?? defaultScore

  return { sentiment, reality, gap } as const
}
