import { useTheme } from '@mui/material'
import MuiBackdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export default function Backdrop(props: { open?: boolean }) {
  const theme = useTheme()
  return (
    <MuiBackdrop
      sx={{
        zIndex: 10,
        color: theme.palette.purple.main,
      }}
      open={props.open ?? true}
    >
      <CircularProgress aria-busy aria-label="Loading" color="inherit" />
    </MuiBackdrop>
  )
}
