import { useQuery } from '@apollo/client'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Client } from '../../../__generated__/graphql'
import { graphql } from '../../../graphql'

const GET_CLIENTS = graphql(`
  query clients {
    clients {
      id
      name
      code
    }
  }
`)

export function useClient() {
  const [client, setClient] = useState<Maybe<Client>>()

  const params = useParams()

  useQuery(GET_CLIENTS, {
    onCompleted: data => {
      const clients = data?.clients ?? []
      const client = clients.find(client => client?.id === params.clientId)

      setClient(client)
    },
  })

  return [client] as const
}
