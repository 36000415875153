import { ReactNode, useState } from 'react'
import { ChatBotAIContext, ChatBotUserContext } from '../Context'

export const ChatBotAIProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isChatVisible, setChatIsVisible] = useState<boolean>()
  const [context, setChatContext] = useState<ChatBotUserContext>()

  return (
    <ChatBotAIContext.Provider
      value={{ isChatVisible, setChatIsVisible, context, setChatContext }}
    >
      {children}
    </ChatBotAIContext.Provider>
  )
}
