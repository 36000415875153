import { useContext } from 'react'
import { FeatureFlagContext } from '../Context/feature-flags'
import { features } from '../featureFlags'

type ArrayElement<A> = A extends readonly (infer T)[] ? T : never
type Feature = ArrayElement<typeof features>

type Keys = [Feature['name'], ...Feature['name'][]]

export function useFeatureFlags(...names: Keys) {
  const features = useContext(FeatureFlagContext)
  const featuresEnabled = features
    .filter(feature => names.includes(feature.name as Feature['name']))
    .map(feature => feature.enabled)

  return featuresEnabled
}
