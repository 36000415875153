import { useQuery } from '@apollo/client'
import { useParams, useSearchParams } from 'react-router-dom'
import { RefinitivDataSource } from '../../../__generated__/graphql'
import { graphql } from '../../../graphql'

const GET_SENTIMENT_EXECUTIVE_SUMMARY = graphql(`
  query GetSentimentExecutiveSummary(
    $industryId: ID
    $clientId: ID
    $startDate: String
    $endDate: String
    $source: RefinitivDataSource
  ) {
    sentimentExecutiveSummary(
      industryId: $industryId
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
      source: $source
    ) {
      mentions {
        companyId
        companyName
        count
      }
      score {
        companyId
        companyName
        score
      }
    }
  }
`)

export function useSentimentExecutiveSummary() {
  const params = useParams()
  const [searchParams] = useSearchParams()

  const query = useQuery(GET_SENTIMENT_EXECUTIVE_SUMMARY, {
    variables: {
      clientId: params.clientId,
      industryId: params.industryId,
      startDate: searchParams.get('startDate'),
      endDate: searchParams.get('endDate'),
      source: searchParams.get('source') as RefinitivDataSource,
    },
  })

  return [query.data?.sentimentExecutiveSummary, query] as const
}
