import { ThemeProvider } from '@mui/material/styles'
import * as ROUTES from 'Config/routes'
import NavigationProvider from 'Context/navigation-context'
import React, { Suspense, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import Backdrop from './Components/backdrop'
import { ChatBotAIProvider } from './Providers/ChatBotAIProvider'
import { ClientProvider } from './Providers/ClientProvider'
import { DataSourceProvider } from './Providers/DataSourceProvider'
import { FeatureFlagProvider } from './Providers/FeatureFlagsProvider'
import { IndustryProvider } from './Providers/IndustryProvider'
import { TimeSeriesProvider } from './Providers/TimeSeriesProvider'
import { UserInfoProvider } from './Providers/UserInfoProvider'
import { features } from './featureFlags'
import ReputationReport from './pages/reputation-report'
import { useBrandedTheme } from './theme'
import { CurrentViewProvider } from './pages/dashboard/pages/home/providers/CurrentViewProvider'
import V2Dashboard from './pages/v2/dashboard'

const Dashboard = React.lazy(() => import('./pages/dashboard'))
const ChangePassword = React.lazy(
  () => import('./pages/registration/change-password'),
)
const NewPassword = React.lazy(
  () => import('./pages/registration/new-password'),
)
const ResetPassword = React.lazy(
  () => import('./pages/registration/reset-password'),
)
const SignIn = React.lazy(() => import('./pages/registration/sign-in'))

const App = () => {
  const theme = useBrandedTheme()

  useEffect(() => {
    const app = import.meta.env.VITE_APP_NAME
    const sha = import.meta.env.VITE_GIT_SHA
    const message = `
      application: ${app}
      git-sha:     ${sha ?? 'unknown'}
      `
    console.info(message)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <FeatureFlagProvider features={features}>
        <UserInfoProvider>
          <ClientProvider>
            <IndustryProvider>
              <TimeSeriesProvider>
                <DataSourceProvider>
                  <CurrentViewProvider>
                    <NavigationProvider>
                      <ChatBotAIProvider>
                        <Suspense fallback={<Backdrop />}>
                          <Routes>
                            <Route index element={<SignIn />} />
                            <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
                            <Route
                              path={ROUTES.RESET_PASSWORD}
                              element={<ResetPassword />}
                            />
                            <Route
                              path={`${ROUTES.NEW_PASSWORD}/:userId/:token`}
                              element={<NewPassword />}
                            />
                            <Route
                              path={ROUTES.CHANGE_PASSWORD}
                              element={<ChangePassword />}
                            />
                            <Route
                              path={`${ROUTES.DASHBOARD}/*`}
                              element={<Dashboard />}
                            />
                            <Route
                              path={`${ROUTES.V2DASHBOARD}/*`}
                              element={<V2Dashboard />}
                            />
                            <Route
                              path="/reputation-report/:clientId/:industryId"
                              element={<ReputationReport />}
                            />
                          </Routes>
                        </Suspense>
                      </ChatBotAIProvider>
                    </NavigationProvider>
                  </CurrentViewProvider>
                </DataSourceProvider>
              </TimeSeriesProvider>
            </IndustryProvider>
          </ClientProvider>
        </UserInfoProvider>
      </FeatureFlagProvider>
    </ThemeProvider>
  )
}

export default App
