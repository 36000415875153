import { Box, Typography } from '@mui/material'
import { Fragment } from 'react'
import { SummaryCard } from '../../components/card/Card'
import { PerceptionRealityGapVerticalChart } from '../../components/perception-reality-gap-vertical'
import { usePerceptionRealityGapData } from '../../hooks/usePerceptionRealityGapData'
import { useSentimentExecutiveSummary } from '../../hooks/useSentimentExecutiveSummary'
import { useSignatureExecutiveSummary } from '../../hooks/useSignatureExecutiveSummary'

const useStyles = () => ({
  title: {
    fontSize: '3.5rem',
    fontWeight: 600,
  },
  subtitle: {
    fontSize: '2rem',
    fontWeight: 300,
  },
  notes: {
    textAlign: 'right',
    fontSize: '.66rem',
    fontWeight: 300,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '3rem',
    height: '100%',
  },
  cardContainer: {
    height: '100%',
  },
  chartContainer: {
    border: `5px solid #596867`,
    borderBottom: 'none',
    borderRadius: '15px 15px 0 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  table: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
  },
})

export const ExecutiveSummaryPage = () => {
  const styles = useStyles()
  const [{ perception, reality, risks, opportunities }] =
    usePerceptionRealityGapData()
  const [sentimentExecutiveSummary] = useSentimentExecutiveSummary()
  const [signatureExecutiveSummary] = useSignatureExecutiveSummary()
  return (
    <>
      <Box m={3} mb={10}>
        <Typography sx={styles.title}>Executive Summary</Typography>

        <Box mt={4} sx={styles.grid}>
          <Box sx={styles.cardContainer}>
            <SummaryCard
              title="Sentiment"
              subTitle="Number of Mentions"
              height="70%"
              content={
                <Box sx={styles.table}>
                  {sentimentExecutiveSummary?.mentions?.map(
                    (company, index) => (
                      <Fragment key={index}>
                        <Box>
                          <Typography>{company?.companyName}</Typography>
                        </Box>
                        <Box>
                          <Typography>{company?.count}</Typography>
                        </Box>
                      </Fragment>
                    ),
                  )}
                </Box>
              }
              highlights={{
                subTitle: 'Sentiment Score',
                content: (
                  <Box sx={styles.table}>
                    {sentimentExecutiveSummary?.score?.map((company, index) => (
                      <Fragment key={index}>
                        <Box>
                          <Typography>{company?.companyName}</Typography>
                        </Box>
                        <Box>
                          <Typography>{company?.score}</Typography>
                        </Box>
                      </Fragment>
                    ))}
                  </Box>
                ),
              }}
            />
          </Box>
          <Box>
            <Box pt={3} sx={styles.chartContainer}>
              <Typography sx={styles.subtitle}>
                Sentiment &mdash; Behavior Gap
              </Typography>

              <Box mt={5} p={3}>
                <PerceptionRealityGapVerticalChart
                  perception={perception}
                  reality={reality}
                  risks={risks}
                  opportunities={opportunities}
                />
              </Box>
            </Box>
          </Box>
          <Box sx={styles.cardContainer}>
            <SummaryCard
              title="Behavior"
              subTitle="Main Reputation Drivers"
              height="70%"
              content={
                <Box sx={styles.table}>
                  {signatureExecutiveSummary?.drivers?.map(
                    (variable, index) => (
                      <Fragment key={index}>
                        <Box>
                          <Typography>{variable?.variableName}</Typography>
                        </Box>
                        <Box>
                          <Typography>{variable?.weight} %</Typography>
                        </Box>
                      </Fragment>
                    ),
                  )}
                </Box>
              }
              highlights={{
                subTitle: 'Key Competitor Gains',
                content: (
                  <Box sx={styles.table}>
                    {signatureExecutiveSummary?.competitorsGains?.map(
                      (company, index) => (
                        <Fragment key={index}>
                          <Box>
                            <Typography>{company?.companyName}</Typography>
                          </Box>
                          <Box>
                            <Typography>
                              {company?.score} (+{' '}
                              {company?.differenceVsPrevious} pts *)
                            </Typography>
                          </Box>
                        </Fragment>
                      ),
                    )}
                    <Box pt={1} gridColumn={'1 / 3'}>
                      <Typography sx={styles.notes}>
                        * compared to previous year
                      </Typography>
                    </Box>
                  </Box>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}
