import { useTheme } from '@mui/material'
import { Maybe } from 'graphql/jsutils/Maybe'
import Highcharts from 'highcharts'
import { HighchartsReact } from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more'
import NoDataModule from 'highcharts/modules/no-data-to-display'
import SolidGaugeModule from 'highcharts/modules/solid-gauge'

HighchartsMore(Highcharts)
SolidGaugeModule(Highcharts)
NoDataModule(Highcharts)

type SentimentScoreGaugeProps = {
  company?: string
  score?: Maybe<number>
  averageScore?: Maybe<number>
  leaderScore?: Maybe<number>
}

const useChartOptions = (
  props: SentimentScoreGaugeProps,
): Highcharts.Options => {
  const theme = useTheme()
  return {
    chart: {
      height: 250,
      width: 250,
      type: 'solidgauge',
      backgroundColor: 'transparent',
    },
    lang: {
      loading: '',
      noData: 'No mentions',
    },

    title: {
      text: '',
    },

    pane: {
      center: ['50%', '50%'],
      size: '100%',
      innerSize: '100%',
      startAngle: 0,
      endAngle: 360,
      background: [
        {
          backgroundColor: Highcharts.color(theme.colors.green.light)
            .setOpacity(0.2)
            .get(),
          borderColor: 'rgb(150, 155, 170)',
          outerRadius: '100%',
          innerRadius: '80%',
          shape: 'arc',
        },
        {
          backgroundColor: Highcharts.color(theme.colors.blue.main)
            .setOpacity(0.2)
            .get(),
          borderColor: 'transparent',
          outerRadius: '79%',
          innerRadius: '70%',
          shape: 'arc',
        },
        {
          backgroundColor: Highcharts.color(theme.colors.blue.light)
            .setOpacity(0.2)
            .get(),
          borderColor: 'rgb(150, 155, 170)',
          outerRadius: '69%',
          innerRadius: '60%',
          shape: 'arc',
        },
      ],
    },

    yAxis: {
      min: 0,
      max: 100,
      visible: false,
    },

    plotOptions: {
      solidgauge: {
        clip: false,
        dataLabels: {
          position: 'center',
          verticalAlign: 'middle',
          borderWidth: 0,
          style: {
            fontFamily: 'Lato',
            fontSize: '32px',
            color: '#fff',
          },
        },
        linecap: 'round',
        stickyTracking: false,
        rounded: true,
      },
    },

    series: [
      {
        type: 'solidgauge',
        name: props.company,
        innerRadius: '80%',
        radius: '100%',
        data: props.score
          ? [
              {
                y: props.score,
                color: theme.colors.green.light,
              },
            ]
          : [],
      },
      {
        type: 'solidgauge',
        name: 'Industry Average',
        innerRadius: '70%',
        radius: '79%',
        data: props.averageScore
          ? [
              {
                y: props.averageScore,
                color: theme.colors.blue.main,
              },
            ]
          : [],
      },
      {
        type: 'solidgauge',
        name: 'Industry Leader',
        innerRadius: '60%',
        radius: '69%',
        data: props.leaderScore
          ? [
              {
                y: props.leaderScore,
                color: theme.colors.blue.light,
              },
            ]
          : [],
      },
    ],

    legend: {
      enabled: true,
      itemStyle: {
        color: '#fff',
        fontFamily: 'Lato',
        fontSize: '14px',
      },
    },

    tooltip: {
      enabled: false,
    },

    exporting: {
      enabled: false,
    },

    credits: {
      enabled: false,
    },
  }
}

export const ScoreGauge = (props: SentimentScoreGaugeProps) => {
  const options = useChartOptions(props)
  return (
    <>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </>
  )
}

export default ScoreGauge
