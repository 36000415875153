import {
  Box,
  Button,
  Chip,
  Popover,
  SxProps,
  Theme,
  ThemeProvider,
  Typography,
  createTheme,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import quarterOfTheYear from 'dayjs/plugin/quarterOfYear'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useState } from 'react'
import { useGoogleAnalytics } from '../../../../../../../hooks/useGoogleAnalytics'
import { DATE_FORMAT } from '../../../../../../../Common/constants'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

dayjs.extend(quarterOfTheYear)

const useStyles = () => {
  const theme = useTheme()
  return {
    buttons: {
      display: 'flex',
      gap: '32px',
      backgroundColor: theme.palette.background.paper,
      height: '60px',
      alignItems: 'center',
      borderRadius: '4px',
    },
    popper: {
      padding: '16px',
    },
    filters: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down(760)]: {
        flexDirection: 'column',
      },
      marginTop: '16px',
    },
    calendar: {
      overflow: 'hidden',
    },
    shortcuts: {
      padding: '0 16px',
      display: 'flex',
      [theme.breakpoints.down(760)]: {
        display: 'none',
      },
      gap: '16px',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  } satisfies Record<string, SxProps<Theme>>
}

type Props = {
  startDate: Maybe<dayjs.Dayjs>
  endDate: Maybe<dayjs.Dayjs>
  onDateRangeChange: (
    startDate: Maybe<dayjs.Dayjs>,
    endDate: Maybe<dayjs.Dayjs>,
  ) => void
}

export const DateRangeCalendar = (props: Props) => {
  const theme = useTheme()

  const downXl = useMediaQuery(theme.breakpoints.down('xl'))

  const customEvent = useGoogleAnalytics()
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(
    props.startDate ?? null,
  )

  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(
    props.endDate ?? null,
  )
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    customEvent('open_calendar')
  }

  const handleClose = () => {
    setAnchorEl(null)
    customEvent('close_calendar')
    props.onDateRangeChange(startDate, endDate)
  }

  const handleSelectPastWeek = () => {
    const startDate = dayjs().subtract(1, 'week').subtract(1, 'day')
    const endDate = dayjs().subtract(1, 'day')
    setStartDate?.(startDate)
    setEndDate?.(endDate)
    setAnchorEl(null)
    customEvent('change_range_select_past_week', {
      startDate: startDate.format(DATE_FORMAT),
      endDate: endDate.format(DATE_FORMAT),
    })
    props.onDateRangeChange(startDate, endDate)
  }

  const handleSelectPastMonth = () => {
    const startDate = dayjs().subtract(1, 'month').subtract(1, 'day')
    const endDate = dayjs().subtract(1, 'day')
    setStartDate?.(startDate)
    setEndDate?.(endDate)
    setAnchorEl(null)
    customEvent('change_range_select_past_month', {
      startDate: startDate.format(DATE_FORMAT),
      endDate: endDate.format(DATE_FORMAT),
    })
    props.onDateRangeChange(startDate, endDate)
  }

  const handleSelectThisWeek = () => {
    const startDate = dayjs().startOf('week')
    const endDate = dayjs().subtract(1, 'day')
    setStartDate?.(startDate)
    setEndDate?.(endDate)
    setAnchorEl(null)
    customEvent('change_range_select_this_week', {
      startDate: startDate.format(DATE_FORMAT),
      endDate: endDate.format(DATE_FORMAT),
    })

    props.onDateRangeChange(startDate, endDate)
  }

  const handleSelectThisMonth = () => {
    const startDate = dayjs().startOf('month')
    const endDate = dayjs().subtract(1, 'day')
    setStartDate?.(startDate)
    setEndDate?.(endDate)
    setAnchorEl(null)
    customEvent('change_range_select_this_month', {
      startDate: startDate.format(DATE_FORMAT),
      endDate: endDate.format(DATE_FORMAT),
    })

    props.onDateRangeChange(startDate, endDate)
  }

  const handleSelectThisQuarter = () => {
    const startDate = dayjs().startOf('quarter')
    const endDate = dayjs().subtract(1, 'day')
    setStartDate?.(startDate)
    setEndDate?.(endDate)
    setAnchorEl(null)
    customEvent('change_range_select_this_quarte', {
      startDate: startDate.format(DATE_FORMAT),
      endDate: endDate.format(DATE_FORMAT),
    })

    props.onDateRangeChange(startDate, endDate)
  }

  const handleSelectLast3Months = () => {
    const startDate = dayjs().subtract(3, 'month').subtract(1, 'day')
    const endDate = dayjs().subtract(1, 'day')
    setStartDate?.(startDate)
    setEndDate?.(endDate)
    setAnchorEl(null)
    customEvent('change_range_select_last_3_months', {
      startDate: startDate.format(DATE_FORMAT),
      endDate: endDate.format(DATE_FORMAT),
    })
    props.onDateRangeChange(startDate, endDate)
  }

  const handleSelectLast6Months = () => {
    const startDate = dayjs().subtract(6, 'month').subtract(1, 'day')
    const endDate = dayjs().subtract(1, 'day')
    setStartDate?.(startDate)
    setEndDate?.(endDate)
    setAnchorEl(null)
    customEvent('change_range_select_last_6_months', {
      startDate: startDate.format(DATE_FORMAT),
      endDate: endDate.format(DATE_FORMAT),
    })
    props.onDateRangeChange(startDate, endDate)
  }

  const handleReset = () => {
    const startDate = dayjs().subtract(2, 'month').subtract(1, 'day')
    const endDate = dayjs().subtract(1, 'day')
    setStartDate?.(startDate)
    setEndDate?.(endDate)
    setAnchorEl(null)
    customEvent('change_range_select_reset', {
      startDate: startDate.format(DATE_FORMAT),
      endDate: endDate.format(DATE_FORMAT),
    })

    props.onDateRangeChange(startDate, endDate)
  }

  const displayDataFormat = downXl ? 'M.D' : 'MMM D'

  return (
    <ThemeProvider
      theme={(theme: Theme) =>
        createTheme({
          ...theme,
          palette: {
            background: {
              default: '#fff',
            },
          },
          typography: {
            allVariants: {
              color: '#000',
            },
          },
        })
      }
    >
      <Box sx={classes.buttons}>
        <Box
          sx={{
            color: 'white',
            display: 'block',
          }}
        >
          <Button
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            endIcon={<ExpandMoreIcon />}
            onClick={handleClick}
            sx={{
              color: 'inherit',
              height: '60px',
            }}
          >
            <Typography variant="h6" color="inherit">
              {startDate?.format(displayDataFormat)} &mdash;{' '}
              {endDate?.format(displayDataFormat)}
            </Typography>
          </Button>
        </Box>
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={classes.popper}>
          <Box>
            <Typography variant="button">Select date range</Typography>
          </Box>
          <Box sx={classes.filters}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box sx={classes.calendar}>
                <Typography variant="body2" style={{ textAlign: 'center' }}>
                  START DATE
                </Typography>
                <DateCalendar
                  disableFuture
                  value={startDate ?? dayjs().subtract(30, 'days')}
                  onChange={(date: dayjs.Dayjs) => {
                    customEvent('choose_start_date', {
                      startDate: date.format(DATE_FORMAT),
                    })
                    setStartDate?.(date)
                  }}
                />
              </Box>
              <Box sx={classes.calendar}>
                <Typography variant="body2" style={{ textAlign: 'center' }}>
                  END DATE
                </Typography>
                <DateCalendar
                  value={endDate ?? dayjs().subtract(1, 'day')}
                  disableFuture
                  onChange={(date: dayjs.Dayjs) => {
                    customEvent('choose_end_date', {
                      endDate: date.format(DATE_FORMAT),
                    })
                    setEndDate?.(date)
                  }}
                />
              </Box>
            </LocalizationProvider>
            <Box sx={classes.shortcuts}>
              <Chip
                label="Last Week"
                onClick={handleSelectPastWeek}
                size="small"
                variant="outlined"
              />
              <Chip
                label="This Week"
                onClick={handleSelectThisWeek}
                size="small"
                variant="outlined"
              />
              <Chip
                label="Last Month"
                onClick={handleSelectPastMonth}
                size="small"
                variant="outlined"
              />
              <Chip
                label="This Month"
                onClick={handleSelectThisMonth}
                size="small"
                variant="outlined"
              />
              <Chip
                label="This Quarter"
                onClick={handleSelectThisQuarter}
                size="small"
                variant="outlined"
              />
              <Chip
                label="Last 3 Month"
                onClick={handleSelectLast3Months}
                size="small"
                variant="outlined"
              />
              <Chip
                label="Last 6 Month"
                onClick={handleSelectLast6Months}
                size="small"
                variant="outlined"
              />
              <Chip
                label="Reset"
                onClick={handleReset}
                size="small"
                variant="outlined"
              />
            </Box>
          </Box>
        </Box>
      </Popover>
    </ThemeProvider>
  )
}
