import { FeatureFlag } from './Context/feature-flags'
import { parseBoolean } from './Utils'

export const features = [
  {
    name: 'feat_financial_module',
    enabled: parseBoolean(import.meta.env.VITE_ENABLE_FINANCIAL_MODULE),
  },
  {
    name: 'feat_media_monitoring_module',
    enabled: parseBoolean(import.meta.env.VITE_ENABLE_MEDIA_MONITORING_MODULE),
  },
  {
    name: 'feat_ai_chatbot',
    enabled: parseBoolean(import.meta.env.VITE_ENABLE_CHATBOT),
  },
  {
    name: 'feat_articles_table',
    enabled: parseBoolean(import.meta.env.VITE_ENABLE_ARTICLES_TABLE),
  },
  {
    name: 'feat_dark_mode',
    enabled: parseBoolean(import.meta.env.VITE_ENABLE_DARK_MODE),
  },
  {
    name: 'feat_insights_summary',
    enabled: parseBoolean(import.meta.env.VITE_ENABLE_INSIGHTS_SUMMARY),
  },
  {
    name: 'feat_darwin-v2',
    enabled: parseBoolean(import.meta.env.VITE_ENABLE_DARWIN_V2),
  },
] as const satisfies FeatureFlag[]
