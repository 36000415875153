import { ReactNode, createContext, useState } from 'react'

export type View = 'overview' | 'industry'
export type ViewContext = {
  view: View
  setView?: (view: View) => void
}

export const CurrentViewContext = createContext<ViewContext>({
  view: 'overview',
})

export const CurrentViewProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [view, setView] = useState<View>('industry')

  return (
    <CurrentViewContext.Provider value={{ view, setView }}>
      {children}
    </CurrentViewContext.Provider>
  )
}
