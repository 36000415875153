import { parseBoolean } from '.'

type PendoConfig = {
  visitor: {
    id: string
    email?: string
    role?: string
  }
  account: {
    id: string
    name?: string
    code?: string
  }
}

export function initialize(config: PendoConfig) {
  if (parseBoolean(import.meta.env.VITE_TEST_ENV)) {
    console.warn('Pendo is not initialized in test environment')
    return
  }
  // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
  // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
  // Call this function in your authentication promise handler or callback when your visitor and account id values are available
  // Please use Strings, Numbers, or Bools for value types.
  window.pendo.initialize({
    visitor: {
      id: config.visitor.id, // Required if user is logged in, default creates anonymous ID
      email: config.visitor.email, // Recommended if using Pendo Feedback, or NPS Email
      role: config.visitor.role, // Optional
      // full_name:    // Recommended if using Pendo Feedback

      // You can add any additional visitor level key-values here,
      // as long as it's not one of the above reserved names.
    },

    account: {
      id: config.account.id, // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
      name: config.account.code, // Optional
      // is_paying:    // Recommended if using Pendo Feedback
      // monthly_value:// Recommended if using Pendo Feedback
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional

      // You can add any additional account level key-values here,
      // as long as it's not one of the above reserved names.
    },
  })
}
