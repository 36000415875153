import { useQuery } from '@apollo/client'
import { cloneDeep } from '@apollo/client/utilities'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { SentimentData } from '../../../__generated__/graphql'
import { graphql } from '../../../graphql'

const GET_SENTIMENT_BY_PILLAR = graphql(`
  query GetSentimentByPillar(
    $industryId: ID!
    $clientId: ID
    $startDate: String
    $endDate: String
  ) {
    sentimentByPillar(
      industryId: $industryId
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
    ) {
      score
      averageScore
      leaderScore
      variableId
      variableName
      dataType
    }
  }
`)

export function useSentimentsByPillar() {
  const params = useParams()
  const [searchParams] = useSearchParams()

  const [data, setData] = useState<Maybe<Maybe<SentimentData>[]>>()

  const query = useQuery(GET_SENTIMENT_BY_PILLAR, {
    variables: {
      clientId: params.clientId,
      industryId: params.industryId ?? '',
      startDate: searchParams.get('startDate'),
      endDate: searchParams.get('endDate'),
    },
    onCompleted: ({ sentimentByPillar }) => {
      setData(cloneDeep(sentimentByPillar) as Maybe<Maybe<SentimentData>[]>)
    },
  })

  const sentiments = data?.filter(
    d => d?.dataType === searchParams.get('source'),
  )
  const environment = sentiments?.find(d => d?.variableName === 'Environment')
  const employees = sentiments?.find(d => d?.variableName === 'Employees')
  const governance = sentiments?.find(d => d?.variableName === 'Governance')
  const dei = sentiments?.find(d => d?.variableName === 'DEI')
  const community = sentiments?.find(d => d?.variableName === 'Community')
  const customers = sentiments?.find(d => d?.variableName === 'Customers')
  const financials = sentiments?.find(d => d?.variableName === 'Financials')

  return [
    {
      environment,
      employees,
      governance,
      dei,
      community,
      customers,
      financials,
    },
    query,
  ] as const
}
