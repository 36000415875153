import { createTheme } from '@mui/material'
import { useMemo } from 'react'
import { branded } from './colors'
import { useDisplayMode } from './hooks/useDisplayMode'

declare module '@mui/material/styles' {
  interface Palette {
    green: Palette['primary']
    blue: Palette['primary']
    purple: Palette['primary']
    magenta: Palette['primary']
    teal: Palette['primary']
  }

  interface PaletteOptions {
    green?: PaletteOptions['primary']
    blue?: PaletteOptions['primary']
    purple?: PaletteOptions['primary']
    magenta?: PaletteOptions['primary']
    teal?: PaletteOptions['primary']
  }

  interface Theme {
    score: {
      positive: string
      good: string
      neutral: string
      bad: string
      negative: string
    }
    pillars: {
      employees: string
      governance: string
      dei: string
      environment: string
      community: string
      customers: string
      financial: string
    }
  }

  interface ThemeOptions {
    score?: {
      positive?: string
      good?: string
      negative?: string
      bad?: string
      neutral?: string
    }
    pillars?: {
      employees?: string
      governance?: string
      dei?: string
      environment?: string
      community?: string
      customers?: string
      financial?: string
    }
  }
}

export function useBrandedTheme() {
  const { mode } = useDisplayMode()

  const theme = useMemo(
    () =>
      createTheme({
        components: {
          MuiUseMediaQuery: {
            defaultProps: {
              noSsr: true,
            },
          },
        },
        palette: {
          mode: mode,
          primary: {
            main: branded.primary[mode],
          },
          secondary: {
            main: branded.secondary[mode],
            dark: 'secondary',
          },
          background: {
            default: branded.background[mode],
            paper: branded.paper[mode],
          },
          green: {
            main: branded.green[mode],
            light: branded['green.light'][mode],
            dark: branded['green.dark'][mode],
            contrastText: branded.contrast.dark,
          },
          blue: {
            main: branded.blue[mode],
            light: branded['blue.light'][mode],
            contrastText: branded.contrast.light,
          },
          purple: {
            main: branded.purple[mode],
            light: branded['purple.light'][mode],
            contrastText: branded.contrast.light,
          },
          magenta: {
            main: branded.magenta[mode],
            contrastText: branded.contrast.light,
          },
          teal: {
            main: branded.teal[mode],
            contrastText: branded.contrast.dark,
          },
          text: {
            primary: branded.text[mode],
          },
        },
        score: {
          positive: '#6FCF97',
          good: '#78AAA7',
          negative: '#E9ABAE',
          bad: '#DAB9BB',
          neutral: '#C4C4C4',
        },
        pillars: {
          employees: '#B7DAF5',
          governance: '#0A2765',
          dei: '#227872',
          environment: '#0F9E93',
          community: '#1AD9B6',
          customers: '#4A608C',
          financial: '#1F78B4',
        },
        typography: {
          fontFamily: ['Lato', 'Raleway', 'sans-serif'].join(', '),
          allVariants: {
            color: branded.text[mode],
          },
        },
      }),
    [mode],
  )

  return theme
}
