import { Box, Typography } from '@mui/material'
import { BackgroundLogo } from '../../../components/background-logo/BackgroundLogo'

const useStyles = () => {
  return {
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      justifySelf: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    },
    title: {
      fontSize: '3.5rem',
      fontWeight: 600,
      color: 'white',
    },
  } satisfies Record<string, React.CSSProperties>
}

export const RealityFrontPage = () => {
  const styles = useStyles()

  return (
    <>
      <Box style={styles.content}>
        <Typography style={styles.title}>Behavior Data</Typography>
      </Box>

      <BackgroundLogo />
    </>
  )
}
