import CheckIcon from '@mui/icons-material/Check'
import { Box, Button, ButtonProps, styled } from '@mui/material'
import isEmpty from 'lodash.isempty'
import React, { ReactElement, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Transition, TransitionStatus } from 'react-transition-group'
import styles from './styles.module.scss'

const duration = 300

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
}

const transitionStyles: Partial<
  Record<TransitionStatus, Record<string, unknown>>
> = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0, pointerEvents: 'none' },
  exited: { opacity: 0, pointerEvents: 'none' },
}

type ButtonContainerProps = {
  content?: string
  align?: 'left' | 'center' | 'right'
  onClick?: () => void
  withIcon?: boolean
  withoutError?: boolean
  withAnimateButton?: boolean
  buttonClassName?: string
}

const MaterialButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText(theme.palette.primary.main),
  fontWeight: 'bold',
}))

const ButtonContainer: React.FC<ButtonContainerProps> = ({
  content = 'Continue',
  align = 'left',
  onClick,
  buttonClassName,
  withIcon = false,
  withoutError = false,
  withAnimateButton = false,
}): ReactElement => {
  const [inProp, setInProp] = useState(withAnimateButton ? false : true)
  const { watch } = useFormContext()
  const watchFields = watch()

  useEffect(() => {
    if (withAnimateButton) {
      setInProp(Object.values(watchFields).every(i => !isEmpty(i)))
    }
  }, [watchFields, withAnimateButton])

  const padding = withoutError ? 32 : 12
  return (
    <Transition in={inProp} timeout={duration}>
      {state => (
        <Box
          component={'div'}
          className={styles.buttonBlock}
          style={{
            ...defaultStyle,
            ...transitionStyles[state],

            justifyContent: align,
            paddingTop: padding,
          }}
        >
          <MaterialButton
            onClick={onClick}
            className={buttonClassName}
            variant="contained"
            color="secondary"
            type="submit"
          >
            {content}
            {withIcon && <CheckIcon className={styles.icon} />}
          </MaterialButton>
        </Box>
      )}
    </Transition>
  )
}

export default ButtonContainer
