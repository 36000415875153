import { ReactNode, useState } from 'react'
import { DataSourceContext } from '../Context'
import { RefinitivDataType } from '../__generated__/graphql'

export const DataSourceProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [dataSource, setDataSource] = useState<RefinitivDataType>(
    RefinitivDataType.NewsSocial,
  )

  return (
    <DataSourceContext.Provider value={{ dataSource, setDataSource }}>
      {children}
    </DataSourceContext.Provider>
  )
}
