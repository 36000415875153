import { Box, SxProps, Theme, Typography, useTheme } from '@mui/material'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useClient } from '../../hooks/useClient'

const useStyles = () => {
  const theme = useTheme()
  return {
    score: {
      color: theme.colors.green.light,
      fontSize: '1.5rem',
      textAlign: 'center',
      lineHeight: '1rem',
    },
    industryAverage: {
      color: theme.colors.blue.main,
      fontSize: '1.5rem',
      textAlign: 'center',
      lineHeight: '1rem',
    },
    industryLeader: {
      color: theme.colors.blue.light,
      fontSize: '1.5rem',
      textAlign: 'center',
      lineHeight: '1rem',
    },
    legend: {
      display: 'flex',
      flexDirection: 'row',
      gap: '3rem',
      padding: '1rem',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  } satisfies Record<string, SxProps<Theme>>
}

type LegendProps = {
  score?: Maybe<number>
  averageScore?: Maybe<number>
  leaderScore?: Maybe<number>
}

export const Legend = (props: LegendProps) => {
  const styles = useStyles()
  const [client] = useClient()
  return (
    <Box sx={styles.legend}>
      <Box>
        <Typography>
          <Box component={'span'} sx={styles.score}>
            &bull;
          </Box>{' '}
          {client?.name}
        </Typography>
        <Typography>
          <Box component={'span'} sx={styles.industryAverage}>
            &bull;
          </Box>{' '}
          Industry average
        </Typography>
        <Typography>
          <Box component={'span'} sx={styles.industryLeader}>
            &bull;
          </Box>{' '}
          Industry leader
        </Typography>
      </Box>
      <Box>
        <Typography>
          <b>{props.score}</b>
        </Typography>
        <Typography>
          <b>{props.averageScore}</b>
        </Typography>
        <Typography>
          <b>{props.leaderScore}</b>
        </Typography>
      </Box>
    </Box>
  )
}
