import React, { useEffect } from 'react'
import { useLocation } from 'react-router'

const ScrollToTop = () => {
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 150)
  }, [location])

  return <></>
}

export default ScrollToTop
