import { Box } from '@mui/material'
import { Maybe } from 'graphql/jsutils/Maybe'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import groupBy from 'lodash.groupby'
import { getPillarsColors } from '../../../../Utils'
import { TrendingTopic } from '../../../../__generated__/graphql'
import { useTrendingTopics } from '../../hooks/useTrendingTopics'

type TrendingTopicsProps = {
  industryAvg?: boolean
  showLegend?: boolean
}

const useStyles = () => ({
  container: {
    height: 600,
    width: 700,
  },
})

const chartOptions = (
  props: TrendingTopicsProps,
  data?: Maybe<TrendingTopic>[],
): Highcharts.Options => {
  const series = Object.entries(groupBy(data, 'pillarName'))
  return {
    chart: {
      type: 'packedbubble',
      height: '80%',
      backgroundColor: 'transparent',
    },
    title: {
      text: '',
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      enabled: props.showLegend ?? true,
      layout: 'proximate',
      align: 'right',
      floating: true,
      itemStyle: {
        color: '#fff',
      },
    },
    plotOptions: {
      packedbubble: {
        minSize: '20%',
        maxSize: '140%',
        layoutAlgorithm: {
          splitSeries: false,
          gravitationalConstant: 0.02,
        },
        dataLabels: {
          enabled: true,
          style: {
            color: '#fff',
            textOutline: 'none',
            fontWeight: 'normal',
          },
          filter: {
            property: 'y',
            operator: '>',
            value: 1,
          },
          format: '{point.name}',
        },
      },
    },
    series: series.map(([pillar, data]) => {
      const color = getPillarsColors().get(pillar)
      return {
        name: pillar,
        color: Highcharts.color(color ?? '#fff')
          .setOpacity(1)
          .get('rgba'),
        type: 'packedbubble',
        data: data.map(d => {
          const value = (props.industryAvg ? d?.averageScore : d?.score) ?? 0

          return {
            name: d?.variableName,
            color: value < 0 ? '#E5A1A4' : color,
            value: +Math.abs(value).toFixed(3),
          }
        }),
      }
    }),
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
  }
}

export const TrendingTopics = (props: TrendingTopicsProps) => {
  const styles = useStyles()
  const [data] = useTrendingTopics()
  const options = chartOptions(props, data)
  return (
    <Box sx={styles.container}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  )
}
