import {
  Box,
  Card,
  CardContent,
  CardHeader,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'

type CardProps = {
  title: string
  subTitle?: string
  height?: string
  content?: React.ReactNode
  highlights?: {
    subTitle?: string
    content?: React.ReactNode
  }
}

const useStyles = (props: CardProps) => {
  return {
    subTitle: {
      fontSize: '1.3rem',
      fontWeight: 600,
    },
    header: {
      backgroundColor: '#00a79c',
      color: '#fff',
      alignItems: 'center',
      textAlign: 'center',
      '& > div > span': {
        fontWeight: 600,
      },
    },
    divider: {
      borderBottom: '1px dashed #fff',
      width: '50%',
    },
    card: {
      borderRadius: '15px',
      border: '1px solid #596867',
      height: props.height ?? 'fit-content',
    },
    content: {
      background: `linear-gradient(75deg, rgb(22, 45, 86) 0%, rgb(5, 163 ,155) 100%)`,
      height: '100%',
    },
    topContent: {
      padding: '1rem',
    },
    childContent: {
      marginTop: '1.5rem',
      padding: '1rem',
      borderRadius: '15px',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
    },
  } satisfies Record<string, SxProps<Theme>>
}

export const SummaryCard = (props: CardProps) => {
  const styles = useStyles(props)
  return (
    <Card sx={styles.card} variant="outlined">
      <CardHeader title={props.title} sx={styles.header}></CardHeader>
      <CardContent sx={styles.content}>
        <Box sx={styles.topContent}>
          <Box mb={1}>
            <Typography sx={styles.subTitle}>{props.subTitle}</Typography>
            <Box sx={styles.divider} />
          </Box>
          {props.content}
        </Box>
        <Box sx={styles.childContent}>
          <Box mb={1}>
            <Typography sx={styles.subTitle}>
              {props.highlights?.subTitle}
            </Typography>
            <Box sx={styles.divider} />
          </Box>
          {props.highlights?.content}
        </Box>
      </CardContent>
    </Card>
  )
}
