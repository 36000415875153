import { Box, SxProps, Theme, Typography, useTheme } from '@mui/material'

const useStyles = () => {
  const theme = useTheme()
  return {
    wrapper: {
      marginTop: '12rem',
      justifySelf: 'center',
    },
    title: {
      fontSize: '3rem',
      fontWeight: 300,
    },
    content: {
      fontSize: '1.75rem',
      fontWeight: 300,
    },
    bullet: {
      fontSize: '1.75rem',
      fontWeight: 600,
      color: theme.palette.secondary.main,
      paddingRight: '1rem',
    },
  } satisfies Record<string, SxProps<Theme>>
}

export const TableOfContents = () => {
  const styles = useStyles()
  return (
    <Box m={3} sx={styles.wrapper}>
      <Typography color="primary" sx={styles.title}>
        Table of Contents
      </Typography>

      <Box mt={3}>
        {[
          'Overview',
          'Executive Summary',
          'Behavior',
          'Sentiment',
          'Sentiment- Behavior Gap',
        ].map((item, index) => (
          <Typography key={index} color="primary" sx={styles.content}>
            <Box component={'span'} sx={styles.bullet}>
              {index + 1}
            </Box>
            {item}
          </Typography>
        ))}
      </Box>
    </Box>
  )
}
