import { useQuery } from '@apollo/client'
import { cloneDeep } from '@apollo/client/utilities'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { GET_TRENDING_TOPICS } from '../../../Graphql/query'
import { TrendingTopic } from '../../../__generated__/graphql'

export function useTrendingTopics() {
  const params = useParams()
  const [searchParams] = useSearchParams()

  const [data, setData] = useState<Maybe<Maybe<TrendingTopic>[]>>()

  const query = useQuery(GET_TRENDING_TOPICS, {
    variables: {
      clientId: params.clientId,
      industryId: params.industryId,
      startDate: searchParams.get('startDate'),
      endDate: searchParams.get('endDate'),
    },
    onCompleted: ({ trendingTopics }) => {
      setData(cloneDeep(trendingTopics) as TrendingTopic[])
    },
  })

  const topics = data?.filter(d => d?.dataType === searchParams.get('source'))

  return [topics, query] as const
}
