import { InputBase, alpha, styled } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'

const Search = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '427px',
  height: '60px',
  color: theme.color.white,
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: alpha('#0C112A', 0.9),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  padding: '0px 20px',
  [theme.breakpoints.down('lg')]: {
    width: '200px',
  },
}))

const SearchIconWrapper = styled('div')(() => ({
  flex: 1,
}))

const StyledInputBase = styled(InputBase)(() => ({
  flex: 10,
  color: 'inherit',
  '& .MuiInputBase-input::placeholder': {
    color: 'inherit',
    opacity: 1,
  },
}))

const HeaderSearch = () => {
  return (
    <Search>
      <StyledInputBase
        placeholder="Why is my sentiment score going down ?"
        inputProps={{ 'aria-label': 'search' }}
      />
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
    </Search>
  )
}

export default HeaderSearch
