import { ReactNode, useState } from 'react'
import { Industry, IndustryContext } from '../Context'

export const IndustryProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [industryId, setIndustry] = useState<Industry>()

  return (
    <IndustryContext.Provider value={{ industryId, setIndustry }}>
      {children}
    </IndustryContext.Provider>
  )
}
