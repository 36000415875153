import {
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material'

type Props = {
  theme?: 'dark' | 'light'
  caption?: string
}

const useStyles = (props: Props) => {
  return {
    table: {
      boxSizing: 'border-box',
      maxWidth: '500px',
      height: 'fit-content',
      padding: '0 1rem 1rem 1rem',
      backgroundColor: props.theme === 'dark' ? 'rgba(0,0,0,.10)' : '#fff',
      border: `1px solid ${props.theme === 'dark' ? '#000' : 'unset'}`,
    },
    tableCell: {
      color: props.theme === 'dark' ? '#fff' : '#000',
      borderBottom: `1px solid ${
        props.theme === 'dark' ? '#929292' : '#c9c2c2'
      }`,
    },
  } satisfies Record<string, SxProps<Theme>>
}

export const ScoreRangeHint = ({ theme = 'dark', caption }: Props) => {
  const styles = useStyles({ theme })
  return (
    <TableContainer component={Paper} sx={styles.table}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell colSpan={5} sx={styles.tableCell}>
              {caption ?? 'Score range'} (0 &ndash; 100)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={styles.tableCell}>Poor</TableCell>
            <TableCell sx={styles.tableCell}>Low</TableCell>
            <TableCell sx={styles.tableCell}>Average</TableCell>
            <TableCell sx={styles.tableCell}>Strong</TableCell>
            <TableCell sx={styles.tableCell}>Excellent</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={styles.tableCell}>0 &ndash; 20</TableCell>
            <TableCell sx={styles.tableCell}>21 &ndash; 40</TableCell>
            <TableCell sx={styles.tableCell}>41 &ndash; 60</TableCell>
            <TableCell sx={styles.tableCell}>61 &ndash; 80</TableCell>
            <TableCell sx={styles.tableCell}>81 &ndash; 100</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
