import { useQuery } from '@apollo/client'
import { cloneDeep } from '@apollo/client/utilities'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { PurposePillar } from '../../../__generated__/graphql'
import { graphql } from '../../../graphql'

const GET_SIGNATURE_MATCH_SCORES = graphql(`
  query signatureMatch($clientId: ID!, $industryId: ID) {
    signatureMatch(clientId: $clientId, industryId: $industryId) {
      name
      value
      previousValue
      compareVs
      color
      optimum
      drilldown {
        name
        value
        previousValue
        compareVs
      }
      optimumDrilldown {
        name
        value
      }
    }
  }
`)

export function useSignatureMatch() {
  const params = useParams()
  const [data, setData] = useState<Maybe<Maybe<PurposePillar>[]>>()

  const query = useQuery(GET_SIGNATURE_MATCH_SCORES, {
    variables: {
      clientId: params.clientId ?? '',
      industryId: params.industryId,
    },
    onCompleted: data => {
      setData(cloneDeep(data.signatureMatch))
    },
  })

  return [data, query] as const
}
