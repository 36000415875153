import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import darwin from 'Assets/darwin-v2-logo.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import * as ROUTES from 'Config/routes'
import { NavigationContext } from '../../../../../../Context/navigation-context'
import { DRAWER_WIDTH } from '../../../../../../Common/constants'

const HeaderLogo = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const theme = useTheme()
  const downMd = useMediaQuery(theme.breakpoints.down('md'))

  const { moveForward } = useContext(NavigationContext)
  const logoOnClickHandler = () => {
    moveForward?.(() => {
      navigate({
        pathname: `${ROUTES.DASHBOARD}`,
        search: location.search,
      })
    })
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: downMd ? '150px' : DRAWER_WIDTH,
      }}
    >
      <Box>
        <img
          src={darwin}
          alt="darwin"
          height="60px"
          onClick={logoOnClickHandler}
        />
      </Box>
      {!downMd && (
        <Typography
          variant="h5"
          align="center"
          sx={{
            color: theme.color.white,
            fontFamily: 'Source Sans 3',
          }}
        >
          Levi Strauss & Co.
        </Typography>
      )}
    </Box>
  )
}

export default HeaderLogo
