import { useQuery } from '@apollo/client'
import { Box, createTheme, ThemeProvider } from '@mui/material'
import {
  ADMIN_ROLE,
  COMPANY_VIEW_ROLE,
  FONT_FAMILY,
  ROLES,
} from 'Common/constants'
import { ErrorBoundaryFallback } from 'Components'
import * as ROUTES from 'Config/routes'
import { ClientContext, UserInfoContext } from 'Context'
import { GET_CURRENT_USER } from 'Graphql/query'
import { initialize as initializePendo } from 'Utils/pendo'
import Highcharts from 'highcharts/highstock'
import Indicators from 'highcharts/indicators/indicators'
import Macd from 'highcharts/indicators/macd'
import PivotPoints from 'highcharts/indicators/pivot-points'
import Accessibility from 'highcharts/modules/accessibility'
import Annotations from 'highcharts/modules/annotations'
import Data from 'highcharts/modules/data'
import ExportMap from 'highcharts/modules/export-data'
import Exporting from 'highcharts/modules/exporting'
import Map from 'highcharts/modules/map'
import Sunburst from 'highcharts/modules/sunburst'
import { useContext, useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useNavigate } from 'react-router'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import Header from './components/header'
import { Navigation } from './components/navigation/Navigation'
import Routes from './routes'
import Footer from './components/footer'

// Load Highcharts modules
Indicators(Highcharts)
PivotPoints(Highcharts)
Macd(Highcharts)
Data(Highcharts)
Sunburst(Highcharts)
Exporting(Highcharts)
ExportMap(Highcharts)
Map(Highcharts)
Accessibility(Highcharts)
Annotations(Highcharts)

Highcharts.setOptions({
  credits: {
    enabled: false,
  },
  exporting: {
    enabled: false,
  },
  chart: {
    zooming: {
      mouseWheel: false,
      singleTouch: false,
    },
    style: {
      fontFamily: FONT_FAMILY,
    },
  },
  legend: {
    itemStyle: {
      fontFamily: FONT_FAMILY,
    },
  },
  xAxis: {
    labels: {
      style: {
        fontFamily: FONT_FAMILY,
      },
    },
  },
  yAxis: {
    labels: {
      style: {
        fontFamily: FONT_FAMILY,
      },
    },
  },
})

declare module '@mui/material/styles' {
  interface Theme {
    color: {
      backgroundGradient: string
      reverseGradient: string
      white: string
      black: string
      coral: string
      green: string
      dodgerBlue: string
      opacityDodgerBlue: string
      darkBlue: string
      divider1: string
      divider2: string
      superRareJade: string
      neutral: string
      transparentWhite: string
    }
    customShadows: {
      lightShadow: string
      heavyShadow: string
    }
  }
  interface ThemeOptions {
    color?: {
      backgroundGradient?: string
      reverseGradient?: string
      white?: string
      black?: string
      coral?: string
      green?: string
      dodgerBlue?: string
      opacityDodgerBlue?: string
      darkBlue?: string
      divider1?: string
      divider2?: string
      superRareJade?: string
      neutral?: string
      transparentWhite?: string
    }
    customShadows?: {
      lightShadow?: string
      heavyShadow?: string
    }
  }
}

const Dashboard = () => {
  const { setUserInfo } = useContext(UserInfoContext)
  const { setClient } = useContext(ClientContext)

  const [enabledDarwinV2] = useFeatureFlags('feat_darwin-v2')

  const navigate = useNavigate()

  useEffect(() => {
    if (!enabledDarwinV2) {
      navigate(
        {
          pathname: `${ROUTES.DASHBOARD}`,
        },
        { replace: true },
      )
    }
  }, [enabledDarwinV2, navigate])

  useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: ({ currentUser }) => {
      if (!currentUser) {
        navigate({
          pathname: `${ROUTES.SIGN_IN}`,
        })
        return
      }

      if (currentUser?.settings?.mustResetPassword) {
        navigate({
          pathname: `${ROUTES.CHANGE_PASSWORD}`,
        })
        return
      }

      const { email, roles } = currentUser
      const ranks = roles.map(role => role.rank)
      const role = ROLES[Math.min(...ranks) as keyof typeof ROLES]
      setUserInfo?.({
        email,
        role: role,
      })
      if (role !== ADMIN_ROLE && role !== COMPANY_VIEW_ROLE) {
        setClient?.({
          clientId: currentUser?.clientId,
          clientCode: currentUser?.clientCode,
          clientName: currentUser?.clientName,
        })
      }

      initializePendo({
        visitor: {
          id: currentUser?.emailHash,
          email: currentUser?.emailHash,
          role: role,
        },
        account: {
          id: currentUser?.clientCode ?? '',
          code: currentUser?.clientCode ?? '',
        },
      })
    },
    onError: () => {
      navigate({
        pathname: `${ROUTES.SIGN_IN}`,
      })
    },
  })

  const theme = createTheme({
    palette: {
      primary: {
        main: '#fff',
      },
      secondary: {
        main: '#00a79c',
      },
      error: {
        main: '#da6c64',
      },
      background: {
        default: '#1E1F5F',
        paper: '#0C112A66',
      },
    },
    typography: {
      fontFamily: 'Source Sans 3',
      allVariants: {
        color: '#fff',
      },
    },
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
    color: {
      backgroundGradient: 'linear-gradient(90deg, #141627 0%, #1B1C5B 100%)',
      reverseGradient: 'linear-gradient(90deg, #1B1C5B 0%, #141627 100%)',
      white: '#fff',
      coral: '#FE6779',
      green: '#1ADBC2',
      black: '#000',
      dodgerBlue: '#18A0FB',
      opacityDodgerBlue: 'rgba(24, 160, 251, 0)',
      darkBlue: '#15172B',
      divider1: 'rgba(153, 153, 153, 0.1)',
      divider2: 'rgba(27, 28, 87, 0.1)',
      superRareJade: '#1BB9B0',
      neutral: 'rgba(254, 103, 121, 0)',
      transparentWhite: 'rgba(255, 255, 255, 0.1)',
    },
    customShadows: {
      lightShadow: '0px 3.5px 5.5px 0px #00000005',
      heavyShadow: '0px 5px 15px rgba(0, 0, 0, 0.3)',
    },
  })

  const [navigationOpen, setNavigationOpen] = useState(false)

  if (!enabledDarwinV2) {
    return null
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: theme.zIndex.appBar,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Header />
        </Box>

        <Box
          sx={{
            display: 'flex',
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              zIndex: theme.zIndex.drawer,
            }}
          >
            <Navigation
              open={navigationOpen}
              handleClose={() => setNavigationOpen(false)}
            />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              backgroundColor: theme.palette.background.default,
              overflow: 'auto',
            }}
          >
            <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
              <Routes />
            </ErrorBoundary>
          </Box>
        </Box>

        <Box
          sx={{
            zIndex: theme.zIndex.appBar,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Footer />
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default Dashboard
