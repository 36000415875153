import backgroundLogo from 'Assets/background-logo.webp'

const useStyles = () => {
  return {
    logo: {
      opacity: 0.3,
      position: 'absolute',
      bottom: 0,
      right: '-45vw',
      height: '100vh',
    },
  } satisfies Record<string, React.CSSProperties>
}

export const BackgroundLogo = () => {
  const styles = useStyles()

  return <img style={styles.logo} src={backgroundLogo} />
}
