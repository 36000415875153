import { Box, SxProps, Theme, Typography } from '@mui/material'
import { TrendingTopics } from '../../../components/trending-topics'

const useStyles = () => {
  return {
    title: {
      fontSize: '2.5rem',
      fontWeight: 300,
    },
    subtitle: {
      fontSize: '1.5rem',
      fontWeight: 300,
    },
    content: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '2rem',
    },
    legendItem: {
      '&:after': {
        backgroundColor: '#e5a1a47e',
        border: '1px solid',
        borderColor: '#e5a1a4',
        borderRadius: '50%',
        content: '""',
        display: 'inline-block',
        height: '10px',
        marginRight: '5px',
        verticalAlign: '5%',
        width: '10px',
      },
    },
  } satisfies Record<string, SxProps<Theme>>
}

export const TrendingTopicsPage = () => {
  const styles = useStyles()

  return (
    <Box m={3}>
      <Typography color="primary" sx={styles.title}>
        Key Topics company and industry sentiment
      </Typography>

      <Box mt={3} sx={styles.content}>
        <Box>
          <Typography color="secondary" sx={styles.subtitle}>
            Trending Topics &bull; Company
          </Typography>

          <TrendingTopics />
        </Box>

        <Box>
          <Typography color="secondary" sx={styles.subtitle}>
            Trending Topics &bull; Industry
          </Typography>

          <TrendingTopics industryAvg={true} showLegend={false} />
        </Box>
      </Box>
      <Box>
        <Typography>
          <Box component={'span'} sx={styles.legendItem} /> Pink color indicates
          negative sentiment
        </Typography>
      </Box>
    </Box>
  )
}
