import { Maybe } from 'graphql/jsutils/Maybe'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Perception, Signature } from '../../../../__generated__/graphql'

type Props = {
  perception: Maybe<Perception>[]
  reality: Maybe<Signature>[]
  risks: Signature[]
  opportunities: Signature[]
}

const chartOptions = (props: Props): Highcharts.Options => {
  const getPoint = (
    signature?: Maybe<Signature>,
  ): { y: number; name: string } => {
    const perception = props.perception.find(
      p => p?.variableName === signature?.name,
    )
    return {
      y: (perception?.score ?? 0) - (signature?.value ?? 0),
      name: signature?.name ?? '',
    }
  }
  const categories = props.reality
    .map(p => p?.name)
    .filter(c =>
      [...props.opportunities, ...props.risks].find(o => o.name === c),
    )
  const opportunities = props.reality
    .filter(p => props.opportunities.find(o => o.name === p?.name))
    .map(getPoint)
  const risks = props.reality
    .filter(p => props.risks.find(o => o.name === p?.name))
    .map(getPoint)

  return {
    chart: {
      width: 400,
      backgroundColor: 'transparent',
      inverted: true,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'category',
      categories: categories.filter((c): c is string => Boolean(c)),
      tickLength: 0,
      gridLineWidth: 0,
      labels: {
        enabled: true,
        style: {
          color: '#fff',
        },
      },
    },
    yAxis: {
      tickLength: 0,
      gridLineWidth: 0,
      title: {
        text: '',
      },
      labels: {
        enabled: false,
      },
    },
    tooltip: {
      shared: true,
    },
    legend: {
      enabled: true,
      itemStyle: {
        color: '#fff',
      },
    },
    series: [
      {
        name: 'Top Opportunities',
        type: 'column',
        color: '#00a79c',
        data: opportunities.map(p => ({
          y: p.y,
          name: p.name,
        })),
      },
      {
        name: 'Top Risks',
        type: 'column',
        color: '#da6c64',
        data: risks.map(p => ({
          y: p.y,
          name: p.name,
        })),
      },
    ],
  }
}

export const PerceptionRealityGapVerticalChart = (props: Props) => {
  return (
    <HighchartsReact highcharts={Highcharts} options={chartOptions(props)} />
  )
}
