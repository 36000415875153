import { ThemeProvider, createTheme } from '@mui/material'
import React from 'react'
import { Page } from './components/page/Page'
import { ExecutiveSummaryPage } from './pages/executive-summary/ExecutiveSummary'
import { FrontPage } from './pages/front-page/FrontPage'
import { OverviewPage } from './pages/overview/OverviewPage'
import { PerceptionRealityGapFrontPage } from './pages/perception-reality-gap/front-page/PerceptionRealityGapFrontPage'
import { PerceptionRealityGapAnalysisFrontPage } from './pages/perception-reality-gap/perception-reality-gap/PerceptionRealityGapAnalysisPage'
import { PerceptionFrontPage } from './pages/perception/front-page/PerceptionFrontPage'
import { PillarSentimentScoresPage } from './pages/perception/pillar-sentiment-scores-page/PillarSentimentScoresPage'
import { TrendingTopicsPage } from './pages/perception/trending-topics-page/TrendingTopicsPage'
import { RealityFrontPage } from './pages/reality/front-page/RealityFrontPage'
import { ReputationScorePage } from './pages/reality/reputation-score/ReputationScorePage'
import { TableOfContents } from './pages/table-of-contents/TableOfContents'
import { CurrentPageProvider } from './providers/CurrentPageProvider'

declare module '@mui/material/styles' {
  interface Theme {
    colors: {
      green: Palette['primary']
      blue: Palette['primary']
    }
  }

  interface ThemeOptions {
    colors?: {
      green?: PaletteOptions['primary']
      blue?: PaletteOptions['primary']
    }
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
    },
    secondary: {
      main: '#00a79c',
    },
    error: {
      main: '#da6c64',
    },
  },
  typography: {
    fontFamily: 'Lato',
    allVariants: {
      color: '#fff',
    },
  },
  colors: {
    green: {
      light: '#15dbc6',
      main: '#15dbc6',
      dark: '#15dbc6',
    },
    blue: {
      light: '#B7DAF5',
      main: '#0747b5',
      dark: '#0747b5',
    },
  },
})

const ReputationReport: React.FC = () => {
  return (
    <CurrentPageProvider>
      <ThemeProvider theme={theme}>
        <Page skipFooter>
          <FrontPage />
        </Page>

        <Page>
          <TableOfContents />
        </Page>

        <Page>
          <OverviewPage />
        </Page>

        <Page>
          <ExecutiveSummaryPage />
        </Page>

        <Page skipFooter>
          <PerceptionFrontPage />
        </Page>

        <Page>
          <PillarSentimentScoresPage />
        </Page>

        <Page>
          <TrendingTopicsPage />
        </Page>

        <Page skipFooter>
          <RealityFrontPage />
        </Page>

        <Page>
          <ReputationScorePage />
        </Page>

        <Page skipFooter>
          <PerceptionRealityGapFrontPage />
        </Page>

        <Page>
          <PerceptionRealityGapAnalysisFrontPage />
        </Page>
      </ThemeProvider>
    </CurrentPageProvider>
  )
}

export default ReputationReport
