// import { graphql } from 'gql.tada'

import { graphql } from '../graphql'

export const GET_CURRENT_USER = graphql(`
  query currentUser {
    currentUser {
      respondentUuid
      clientName
      clientCode
      clientId
      email
      emailHash
      roles {
        name
        rank
      }
      settings {
        mustResetPassword
      }
    }
  }
`)

export const GET_CLIENTS = graphql(`
  query clients {
    clients {
      id
      name
      code
    }
  }
`)

export const USER_DETAILS = graphql(`
  query UserDetails($clientId: ID!, $teamsIds: [ID]) {
    userDetails(clientId: $clientId, teamsIds: $teamsIds) {
      id
      email
      roleId
      role {
        name
        rank
      }
      accountStatus
      jobTitle
    }
  }
`)

export const GET_WHITELISTED_EMAILS = graphql(`
  query EmailWhitelist($clientId: ID!) {
    emailWhitelist(clientId: $clientId) {
      id
      clientId
      emailOrDomain
    }
  }
`)

export const CHECK_IF_EMAIL_WHITELISTED = graphql(`
  query CheckIfEmailWhitelisted($client: String!, $email: String!) {
    checkIfEmailWhitelisted(client: $client, email: $email)
  }
`)

export const GET_YEARS = graphql(`
  query yearsInPurposeSignatureChart {
    yearsInPurposeSignatureChart
  }
`)

export const GET_COMPANIES = graphql(`
  query companiesInPurposeSignatureChart($clientId: ID!) {
    companiesInPurposeSignatureChart(clientId: $clientId) {
      id
      name
      clientCode
    }
  }
`)

export const GET_TEAM_PURPOSE_SIGNATURE = graphql(`
  query teamPurposeSignature(
    $year: Int!
    $companyId: ID!
    $groupId: ID!
    $limit: Int
  ) {
    teamPurposeSignature(
      year: $year
      companyId: $companyId
      groupId: $groupId
      limit: $limit
    ) {
      data {
        score
        name
        current
      }
      optimum {
        score
        name
      }
      dataForSignatureMatch {
        name
        y
      }
    }
  }
`)

export const GET_SESSION_PURPOSE_SIGNATURE = graphql(`
  query sessionPurposeSignature(
    $clientId: ID
    $groupId: ID
    $companyId: ID
    $limit: Int
    $restoreUserSession: Boolean
  ) {
    sessionPurposeSignature(
      clientId: $clientId
      groupId: $groupId
      companyId: $companyId
      limit: $limit
      restoreUserSession: $restoreUserSession
    ) {
      data {
        score
        name
        current
      }
      optimum {
        score
        name
      }
      company {
        score
        name
      }
      dataForSignatureMatch {
        name
        y
        original
        company
        scoreVsCompany
        originalVsCompany
      }
    }
  }
`)

export const GET_PURPOSE_DRIVERS = graphql(`
  query purposeDriversSignature($industryId: ID) {
    purposeDriversSignature(industryId: $industryId) {
      name
      value
      color
      differenceWithPastQuarter
      compareVsPeriod
    }
  }
`)

export const GET_REPUTATION_SCORE = graphql(`
  query reputationScore($clientId: ID!, $industryId: ID) {
    reputationScore(clientId: $clientId, industryId: $industryId) {
      score
      difference
      compareVsPeriod
    }
  }
`)

export const GET_COMPANY_SCORES_VS_PURPOSE_DRIVEN = graphql(`
  query companyVsTopMostPurposeDriven($clientId: ID!, $industryId: ID) {
    companyVsTopMostPurposeDriven(
      clientId: $clientId
      industryId: $industryId
    ) {
      name
      value
      color
      differenceWithPastQuarter
      compareVsPeriod
    }
  }
`)

export const GET_PURPOSE_PILLARS_SCORES = graphql(`
  query purposePillars($clientId: ID!, $industryId: ID) {
    purposePillars(clientId: $clientId, industryId: $industryId) {
      name
      value
      previousValue
      compareVs
      color
      optimum
      drilldown {
        name
        value
        previousValue
        compareVs
        drilldown {
          name
          value
          previousValue
          compareVs
        }
        optimumDrilldown {
          name
          value
        }
      }
      optimumDrilldown {
        name
        value
      }
    }
  }
`)

export const GET_HIGH_RISK_AREAS_SCORES = graphql(`
  query highRiskAreas($clientId: ID!, $industryId: ID) {
    highRiskAreas(clientId: $clientId, industryId: $industryId) {
      name
      value
      previousValue
      compareVs
      color
      optimum
      drilldown {
        name
        value
        previousValue
        compareVs
      }
      optimumDrilldown {
        name
        value
      }
    }
  }
`)

export const GET_SIGNATURE_MATCH_SCORES = graphql(`
  query signatureMatch($clientId: ID!, $industryId: ID) {
    signatureMatch(clientId: $clientId, industryId: $industryId) {
      name
      value
      previousValue
      compareVs
      color
      optimum
      drilldown {
        name
        value
        previousValue
        compareVs
      }
      optimumDrilldown {
        name
        value
      }
    }
  }
`)

export const EXPLORE_COMPANY_DATA_BY_YEAR = graphql(`
  query exploreDataByYear($clientId: ID!, $industryId: ID) {
    exploreDataByYear(clientId: $clientId, industryId: $industryId) {
      categories
      data {
        name
        color
        data
      }
    }
  }
`)

export const EXPLORE_COMPANY_DATA_BY_INDUSTRY = graphql(`
  query exploreDataByIndustry($clientId: ID!, $industryId: ID) {
    exploreDataByIndustry(clientId: $clientId, industryId: $industryId) {
      categories
      average
      data {
        name
        color
        data
      }
    }
  }
`)

export const GET_TRENDING_TOPICS = graphql(`
  query GetTrendingTopics(
    $industryId: ID
    $clientId: ID
    $startDate: String
    $endDate: String
  ) {
    trendingTopics(
      industryId: $industryId
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
    ) {
      score
      averageScore
      variableId
      variableName
      pillarName
      dataType
    }
  }
`)

export const GET_INDUSTRIES = graphql(`
  query industries($clientId: ID) {
    industries(clientId: $clientId) {
      id
      name
      forClient
    }
  }
`)

export const GET_PURPOSE_MATCH = graphql(`
  query purposeMatch($clientId: ID!) {
    purposeMatch(clientId: $clientId)
  }
`)

export const GET_VARIABLE_GROUPS = graphql(`
  query variableGroups {
    variableGroups {
      id
      name
    }
  }
`)

export const GET_HOME_SENTIMENT_SCORE = graphql(`
  query GetHomeTabSentimentScore($industryId: ID!, $clientId: ID) {
    sentimentScore(industryId: $industryId, clientId: $clientId) {
      score
      averageScore
      leaderScore
      dataType
    }
  }
`)

export const GET_HOME_REPUTATION_SCORE = graphql(`
  query GetHomeTabReputationScore($clientId: ID!, $industryId: ID) {
    reputationScore(clientId: $clientId, industryId: $industryId) {
      score
      averageScore
      leaderScore
    }
  }
`)

export const GET_HOME_PERCEPTION_REALITY_GAP_SCORE = graphql(`
  query GetHomeTabPerceptionRealityGapScore($clientId: ID!, $industryId: ID) {
    perceptionRealityGapScore(clientId: $clientId, industryId: $industryId) {
      score
      averageScore
      leaderScore
      dataType
    }
  }
`)
