import { useQuery } from '@apollo/client'
import debounce from 'lodash.debounce'
import { useCallback } from 'react'
import { GET_CURRENT_USER } from '../Graphql/query'
import { ga } from '../Utils'
import { useLocation } from 'react-router-dom'

const debouncedEvt = debounce(ga.customEvent, 10)

export function useGoogleAnalytics() {
  const location = useLocation()
  const url = location.pathname

  const query = useQuery(GET_CURRENT_USER, {
    fetchPolicy: 'cache-only',
  })

  const customEvent = useCallback(
    (event: string, args?: Record<string, unknown>) => {
      if (!query.data?.currentUser) {
        console.error(
          'This event wont be tracked because user property is not available' +
            ' please ensure that you call this function from event handler and user info is available by then',
        )
        return
      }
      const { emailHash, clientCode } = query.data.currentUser
      debouncedEvt(event, {
        user_id: emailHash,
        client: clientCode,
        url: url,
        ...args,
      })
    },
    [query.data?.currentUser, url],
  )

  return customEvent
}
