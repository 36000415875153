import { Box, SxProps, Theme, Typography, useTheme } from '@mui/material'
import { PerceptionRealityGapChart } from '../../../components/perception-reality-gap-chart'
import { usePerceptionRealityGapData } from '../../../hooks/usePerceptionRealityGapData'

const useStyles = () => {
  const theme = useTheme()
  return {
    title: {
      fontSize: '3.5rem',
      fontWeight: 600,
    },
    subtitle: {
      fontSize: '2rem',
      fontWeight: 300,
    },
    container: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
      gap: '3rem',
      margin: '3rem 5rem',
    },
    chart: {
      backgroundColor: theme.palette.grey[200],
      borderRadius: '1rem',
    },
    highlights: {
      backgroundColor: '#0a0b33',
      borderRadius: '1rem',
    },
    highlightsContainer: {
      display: 'grid',
      gridTemplateRows: 'auto',
      gap: '3rem',
    },
  } satisfies Record<string, SxProps<Theme>>
}

export const PerceptionRealityGapAnalysisFrontPage = () => {
  const styles = useStyles()
  const [{ opportunities, risks, perception, reality }] =
    usePerceptionRealityGapData()

  return (
    <>
      <Box m={3}>
        <Typography sx={styles.title}>
          Sentiment &mdash; Behavior Gap Analysis
        </Typography>
        <Typography color="secondary" sx={styles.subtitle}>
          Highlights Risks and Opportunities
        </Typography>

        <Box sx={styles.container}>
          <Box p={5} sx={styles.chart}>
            <PerceptionRealityGapChart
              perception={perception}
              reality={reality}
            />
          </Box>

          <Box sx={styles.highlightsContainer}>
            {Boolean(opportunities.length) && (
              <Box sx={styles.highlights} p={3}>
                <Typography color="secondary">
                  Largest opportunities:
                </Typography>
                <ol>
                  {opportunities.map(opportunity => (
                    <Typography>
                      <li>{opportunity.name}</li>
                    </Typography>
                  ))}
                </ol>
              </Box>
            )}

            {Boolean(risks.length) && (
              <Box sx={styles.highlights} p={3}>
                <Typography color="error">Largest risks:</Typography>
                <ol>
                  {risks.map(risk => (
                    <Typography>
                      <li>{risk.name}</li>
                    </Typography>
                  ))}
                </ol>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  )
}
